<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div style="clear: both">
        <h3>一般要求或原则<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.principles')"/></h3>
        <preview/>
        <docItemRemarks :path="'content.principles'"/>
        <template v-for="(principle, index) in content.principles">
          <div :class="(content2 && !content2.principles[index]) ? 'modifyTag':'divUnmark'" :key="principle.k">
            <h4 style="margin-left:20px;line-height:24px;float: left">原则:</h4>
            <docItem :valCn.sync="principle.name" :valEn.sync="principle.name_E" :path="'content.principles.' + principle.k + '.name'" :w="500"
                     :modifiedCn="content2 && content2.principles[index] && principle.name != content2.principles[index].name"
                     :ModifiedEn="content2 && content2.principles[index] && principle.name_E != content2.principles[index].name_E"/>
            <div style="padding: 20px" v-for="(desc, idx) in principle.des" :key="desc.k">
              <div v-if="CnOn">
                <pre v-if="!CnEditing" style="white-space: pre-wrap; word-wrap: break-word;" v-html="displayContent['content.principles.' + principle.k + '.des.' + desc.k + '.v']"
                     :class="(content2 && content2.principles[index] && content2.principles[index].des[idx] && desc.v != content2.principles[index].des[idx].v) ? 'modifyTag':'pUnmark'">{{desc.v}}</pre>
                <el-input v-else v-model="desc.v" @input="updateDisplayContent('content.principles.' + principle.k + '.des.' + desc.k + '.v', desc.v)"
                          :id="'content.principles.' + principle.k + '.des.' + desc.k + '.v'" type="textarea" :autosize="{ minRows: 2}"/>
                <el-button v-if="CnEditing" @click="add(principle.des, idx,{v_E:'',v:'',k:genNewK()},content2 ? content2.principles:null)" class="el-icon-plus smallButton"/>
                <i @click="comment('content.principles.' + principle.k + '.des.' + desc.k + '.v')" style="color: #0D8FBF;float: right" class="el-icon-s-comment"/>
                <el-popover v-if="CnEditing" placement="right" width="180" trigger="click">
                  <div style="float: left">
                    <el-button type="primary" size="mini" @click="selRelationDoc('content.principles.' + principle.k + '.des.' + desc.k + '.v','doc')">文件</el-button>
                    <el-button type="primary" size="mini" @click="selRelationDoc('content.principles.' + principle.k + '.des.' + desc.k + '.v','law')">法规</el-button>
                    <el-button type="primary" size="mini" @click="selRelationDoc('content.principles.' + principle.k + '.des.' + desc.k + '.v','abbr')">缩略语</el-button>
                    <el-button type="primary" size="mini" v-if="principle.des.length > 1" @click="strikeOut(principle.des,idx,(content2 && content2.principles[index] && content2.principles[index].des[idx]) ? content2.principles[index].des[idx].v:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < principle.des.length-1" @click="swap(principle.des,idx,(content2 && content2.principles[index] && content2.principles[index].des[idx]) ? content2.principles[index].des[idx].v:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(principle.des,idx-1,(content2 && content2.principles[index] && content2.principles[index].des[idx]) ? content2.principles[index].des[idx].v:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference" class="smallButton">...</el-button>
                </el-popover>
                <docItemRemarks :path="'content.principles.' + principle.k + '.des.' + desc.k + '.v'"/>
              </div>
              <div v-if="EnOn" style="background:#FDE2E7">
                <pre v-if="!EnEditing" v-html="displayContent['content.principles.' + principle.k + '.des.' + desc.k + '.v_E'] || desc.v_E" style="white-space: pre-wrap; word-wrap: break-word;">{{ desc.v_E }}</pre>
                <el-input v-else v-model="desc.v_E" @input="updateDisplayContent('content.principles.' + principle.k + '.des.' + desc.k + '.v_E', desc.v_E)"
                          :id="'content.principles.' + principle.k + '.des.' + desc.k + '.v_E'" type="textarea" :autosize="{ minRows: 2}"/>
                <i @click="comment('content.principles.' + principle.k + '.des.' + desc.k + '.v_E')" style="color: #0D8FBF;float: right" class="el-icon-s-comment"/>
                <el-button v-if="EnEditing" @click="add(principle.des, idx,{v_E:'',v:'',k:genNewK()},content2 ? content2.principles:null)" class="el-icon-plus smallButton"/>
                <el-popover v-if="EnEditing" placement="right" width="180" trigger="click">
                  <div style="float: left">
                    <el-button type="primary" size="mini" @click="selRelationDoc('content.principles.' + principle.k + '.des.' + desc.k + '.v_E','doc')">文件</el-button>
                    <el-button type="primary" size="mini" @click="selRelationDoc('content.principles.' + principle.k + '.des.' + desc.k + '.v_E','law')">法规</el-button>
                    <el-button type="primary" size="mini" @click="selRelationDoc('content.principles.' + principle.k + '.des.' + desc.k + '.v_E','abbr')">缩略语</el-button>
                    <el-button type="primary" size="mini" v-if="principle.des.length > 1" @click="strikeOut(principle.des,idx,(content2 && content2.principles[index] && content2.principles[index].des[idx]) ? content2.principles[index].des[idx].v:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < principle.des.length-1" @click="swap(principle.des,idx,(content2 && content2.principles[index] && content2.principles[index].des[idx]) ? content2.principles[index].des[idx].v:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(principle.des,idx-1,(content2 && content2.principles[index] && content2.principles[index].des[idx]) ? content2.principles[index].des[idx].v:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference" class="smallButton">...</el-button>
                </el-popover>
                <docItemRemarks :path="'content.principles.' + principle.k + '.des.' + desc.k + '.v_E'"/>
              </div>
              <docItemRemarks :path="'content.principles.' + principle.k + '.des.' + desc.k"/>
            </div>
            <el-button v-if="editing" @click="add(content.principles, index,{name:'',name_E:'', des:[{v:'',v_E:'',k:genNewK()}], k:genNewK()},content2 ? content2.principles:null)" class="el-icon-plus smallButton"/>
            <i @click="comment('content.principles.' + principle.k)" style="color: #0D8FBF;float: right" class="el-icon-s-comment"/>
            <el-popover v-if="content.principles.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.principles.length > 1" @click="strikeOut(content.principles,index,content2 ? content2.principles:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="index < content.principles.length-1" @click="swap(content.principles,index,content2 ? content2.principles:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="index > 0" @click="swap(content.principles,index-1,content2 ? content2.principles:null)" class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference" class="smallButton">...</el-button>
            </el-popover>
          </div>
          <div v-if="showComment" class="remark" :key="principle.k + 'c'">
            <docItemRemarks :path="'content.principles.' + principle.k"/>
          </div>
        </template>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";
import preview from "./preview.vue";

export default {
  name: "principles",

  mixins:[tabMix],

  components:{preview},

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>