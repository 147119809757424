<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div>
        <h3 style="padding-bottom: 10px">附件<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.annexes')"/>
          <el-button v-if="editing" type="primary" size="mini" @click="autoLinkRefDocs('annex')">侦测附件引用</el-button>
          <el-button v-if="editing" type="primary" size="mini" @click="addAnnex">添加附件</el-button>
        </h3>
        <docItemRemarks :path="'content.annexes'"/>
        <el-form-item>
          <table class="table">
            <tr>
              <th>序号</th>
              <th>附件编号<el-button type="mini" @click="sorted('annexes', 'num')">排序</el-button></th>
              <th>附件名称</th>
            </tr>
            <template v-for="(annexe,idx) in content.annexes">
              <tr :key="annexe.k">
                <td style="width: 5%" :class="(content2 && !content2.annexes[idx]) ? 'modifyTag':'tdUnmark'">
                  <a :class="annexe.nothingness ? 'refNon':'refBe'" v-if="annexe.refId" @click="previewAnnexe(annexe.refId)">{{idx+1}}<a class="el-icon-paperclip"/></a>
                  <span v-else>{{idx+1}}</span>
                </td>
                <td style="width: 30%" :class="(content2 && !content2.annexes[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput style="padding-top: 10px" :editing="editing" :val.sync="annexe.num" :path="'content.annexes.' + annexe.k + '.num'"
                                 :modified="content2 && content2.annexes[idx] && annexe.num !=content2.annexes[idx].num" :w="285"/>
                </td>
                <td :class="(content2 && !content2.annexes[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="annexe.name" :valEn.sync="annexe.name_E" :path="'content.annexes.' + annexe.k + '.name'" :w="620"
                           :modifiedCn="content2 && content2.annexes[idx] && annexe.name !=content2.annexes[idx].name"
                           :modifiedEn="content2 && content2.annexes[idx] && annexe.name_E !=content2.annexes[idx].name_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.annexes, idx,Annex(),content2 ? content2.annexes:null)" class="el-icon-plus"/>
                  <i @click="comment('content.annexes.' + annexe.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'annex',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                      <el-button type="primary" size="mini" v-if="content.annexes.length > 1" @click="strikeOut(content.annexes,idx,content2 ? content2.annexes:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.annexes.length-1" @click="swap(content.annexes,idx,content2 ? content2.annexes:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.annexes,idx-1,content2 ? content2.annexes:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.annexes.' + annexe.k]" :key="annexe.k + 'c'">
                <td colspan="3" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.annexes.' + annexe.k"/>
                </td>
              </tr>
            </template>
          </table>
          <div v-if="recommendAnnexes.length>0" class="recommendBack">
            <table class="table">
              <tr>
                <th>序号</th>
                <th>附件编号</th>
                <th>附件名称</th>
                <th>版本号</th>
              </tr>
              <tr v-for="(annex,idx) in recommendAnnexes">
                <td style="width:50px;border: 1px solid">
                  <a style="color: #0D8FBF" @click="previewRefFlow(annex.id)">{{ idx + 1 }}<a
                      class="el-icon-paperclip"/></a>
                </td>
                <td style="width: 20%;border: 1px solid">{{ annex.num }}</td>
                <td style="border: 1px solid">{{ annex.name }}</td>
                <td style="width: 20%;border: 1px solid">{{ annex.ver }}</td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="content.annexes.push({num: annex.num, name:annex.name, name_E:annex.name_E, ver: annex.ver, refId:annex.id, k:genNewK()});recommendAnnexes.splice(idx,1)"
                             style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary" size="mini" class="el-icon-top-right"/>
                </td>
              </tr>
            </table>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <editDocDlg ref="editDocDlg" :depts="depts" :pId="doc.prjtId" :typeN="'附件'" :type="DOC_TYPE.annex" @oked="addConAnnex"></editDocDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "annexes",

  mixins:[tabMix],

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>