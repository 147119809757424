<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div style="padding-top: 10px">
        <h4>参考法规 Related Laws or Regulations<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.refLaws')"/></h4>
        <docItemRemarks :path="'content.refLaws'"/>
        <table class="table">
          <tr>
            <th>序号</th>
            <th>法规名称<el-button type="mini" @click="sorted('refLaws', 'name')">排序</el-button></th>
            <th>发布机构</th>
            <th>发布号</th>
            <th>发布年月</th>
          </tr>
          <template v-for="(refLaw,idx) in content.refLaws">
            <tr :key="refLaw.k">
              <td style="width:50px;" :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                <a style="color: #0D8FBF" v-if="refLaw.linkUrl" :href="refLaw.linkUrl" target="_blank">{{idx+1}}<a class="el-icon-paperclip"/></a>
                <span v-else>{{idx+1}}</span>
              </td>
              <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="refLaw.name" :valEn.sync="refLaw.name_E" :path="'content.refLaws.' + refLaw.k + '.name'" :w="218"
                         :modifiedCn="content2 && content2.refLaws[idx] && refLaw.name !=content2.refLaws[idx].name"
                         :modifiedEn="content2 && content2.refLaws[idx] && refLaw.name_E !=content2.refLaws[idx].name_E"/>
              </td>
              <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="refLaw.issuer" :valEn.sync="refLaw.issuer_E" :path="'content.refLaws.' + refLaw.k + '.issuer'" :w="218"
                         :modifiedCn="content2 && content2.refLaws[idx] && refLaw.issuer !=content2.refLaws[idx].issuer"
                         :modifiedEn="content2 && content2.refLaws[idx] && refLaw.issuer_E !=content2.refLaws[idx].issuer_E"/>
              </td>
              <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="refLaw.num" :valEn.sync="refLaw.num_E" :path="'content.refLaws.' + refLaw.k + '.num'" :w="218"
                         :modifiedCn="content2 && content2.refLaws[idx] && refLaw.num !=content2.refLaws[idx].num"
                         :modifiedEn="content2 && content2.refLaws[idx] && refLaw.num_E !=content2.refLaws[idx].num_E"/>
              </td>
              <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="refLaw.date" :valEn.sync="refLaw.date_E" :path="'content.refLaws.' + refLaw.k + '.date'" :w="218"
                         :modifiedCn="content2 && content2.refLaws[idx] && refLaw.date !=content2.refLaws[idx].date"
                         :modifiedEn="content2 && content2.refLaws[idx] && refLaw.date_E !=content2.refLaws[idx].date_E"/>
              </td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="add(content.refLaws, idx,RefLaw(),content2 ? content2.refLaws:null)" class="el-icon-plus"/>
                <i @click="comment('content.refLaws.' + idx)" class="el-icon-s-comment"/>
                <el-popover v-if="editing" placement="right" width="180" trigger="click">
                  <div style="float: left">
                    <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'law',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                    <el-button type="primary" size="mini" v-if="content.refLaws.length > 1" @click="strikeOut(content.refLaws,idx,content2 ? content2.refLaws:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < content.refLaws.length-1" @click="swap(content.refLaws,idx,content2 ? content2.refLaws:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.refLaws,idx-1,content2 ? content2.refLaws:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference">...</el-button>
                </el-popover>
              </td>
            </tr>
            <tr v-if="showComment && remarksMap['content.refLaws.' + idx]" :key="refLaw.k + 'c'">
              <td colspan="5" class="rowRemarkBorder">
                <docItemRemarks :path="'content.refLaws.' + idx"/>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "laws",

  mixins:[tabMix],

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>