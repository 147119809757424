import { render, staticRenderFns } from "./editManual2.vue?vue&type=template&id=540b97ef&scoped=true&"
import script from "./editManual2.vue?vue&type=script&lang=js&"
export * from "./editManual2.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=540b97ef&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "540b97ef",
  null
  
)

export default component.exports