<template>
  <div>
    <el-popover placement="right" style="width: auto" trigger="click">
      <div style="float: left">
        <el-button type="primary" size="mini" @click="previewLaw">法规</el-button>
        <el-button type="primary" size="mini" @click="seeDoc">质量文件</el-button>
        <el-button type="primary" size="mini" @click="previewAbbr">缩略语</el-button>
        <el-button type="primary" size="mini" @click="previewTerm">术语库</el-button>
      </div>
      <el-button type="primary" size="mini" slot="reference" style="float: left;margin-left: 10px">查看</el-button>
    </el-popover>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "preview",

  mixins:[tabMix],
}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>