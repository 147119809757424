import REQ from "@/app/REQ";
import docEditState from "@/doc/docEditState";
import {DOC_TYPE} from "@/consts";


export default {
    name: "navTopMix",
    inject:['gainDoc',],

    data() {
        return {
        }
    },

    methods: {
        //保存
        onSave() {
            let {content} = docEditState
            if(this.doc.type == DOC_TYPE.sop){
                content.flows.forEach(flow => flow.steps.forEach(step => {
                    if(step.roles) {
                        step.role = step.roles.filter(r => r.role).map(r => r.role).join('/')
                        step.role_E = step.roles.filter(r => r.role_E).map(r => r.role_E).join('/')
                    }
                }))
            }
            this.req2Data(REQ.upDoc, {id: this.doc.id, content, kMax: docEditState.kMax, design: docEditState.design}, {},
                () => {
                    this.ok('修改成功')
                    docEditState.editing = false
                    if(this.doc.type == DOC_TYPE.sop)this.getDocPrjt()
                    this.getRemarks()
                    this.screenStaffs(docEditState.docStaffs, docEditState.prjtStaffs, docEditState.staffs)
                    this.getDocs()
                });
        },
    }
};