import formDlgMix from "@/gDlgs/formDlgMix";

export default {
    name: "selDeptsDlg",
    mixins: [formDlgMix],
    props: ['depts'],

    data() {
        return {
            checkAll: false,
            checkedDeptIds: [],
            isIndeterminate: false,
        }
    },

    watch: {
        working(){
            Object.assign(this, {checkAll: '', checkedDeptIds: [], isIndeterminate: false,})
        }
    },

    methods: {
        handleCheckAllChange(val) {
            this.checkedDeptIds = val ? this.depts.map(it => it.id): [];
            if(val) this.checkedDeptIds.push('allCompany')
            this.isIndeterminate = false;
        },

        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.depts.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.depts.length;
        },

        onOk(){
            let {checkedDeptIds} =this
            this.$emit('oked',checkedDeptIds)
            this.working =false
        },
    }
};
