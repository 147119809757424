<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div style="padding-top: 10px">
        <h4>角色与职责<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.duties')"/>
          <el-button type="primary" v-if="editing" size="mini" @click="autoRefRole()">自动关联</el-button>
          <el-button v-if="editing" type="primary" size="mini" @click="autoLinkRoles()">角色同步至职责</el-button>
        </h4>
        <preview/>
        <docItemRemarks :path="'content.duties'"/>
        <el-form-item>
          <table class="table">
            <tr>
              <th>角色</th>
              <th>职责描述</th>
            </tr>
            <template v-for="(duty,idx) in content.duties">
              <tr :key="duty.k">
                <td  style="width: 20%" :class="(content2 && !content2.duties[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="duty.role" :valEn.sync="duty.role_E" :valId.sync="duty.roleId" :path="'content.duties.' + duty.k + '.role'" :w="185"
                           :modifiedCn="content2 && content2.duties[idx] && duty.role != content2.duties[idx].role" :options="design.deployRoles"
                           :modifiedEn="content2 && content2.duties[idx] && duty.role_E != content2.duties[idx].role_E"/>
                </td>
                <td :class="(content2 && !content2.duties[idx]) ? 'modifyTag':'tdUnmark'">
                  <div v-for="(des,idex) in duty.des" :key="des.k">
                    <div v-if="CnOn">
                      <i v-if="!CnEditing" @click="comment('content.duties.' + duty.k + '.des.' + des.k + '.v')" class="el-icon-s-comment"/>
                      <pre style="width: 748px;float: left" v-html="displayContent['content.duties.' + duty.k + '.des.' + des.k + '.v']"
                           v-if="!CnEditing && des.v" :class="(content2 && content2.duties[idx] && content2.duties[idx].des[idex] && des.v != content2.duties[idx].des[idex].v) ? 'modifyTag':'pUnmark'">● {{des.v}}</pre>
                      <p v-if="CnEditing" style="width: 10px;float: left">● </p>
                      <el-input v-if="CnEditing" style="width: 748px" @input="updateDisplayContent('content.duties.' + duty.k + '.des.' + des.k + '.v', des.v)"
                                :id="'content.duties.' + duty.k + '.des.' + des.k + '.v'" v-model="duty.des[idex].v" type="textarea" :autosize="{ minRows: 2}"/>
                      <div v-if="CnEditing" style="width: 15px;float: right">
                        <el-button @click="add(duty.des, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-plus"/>
                        <i @click="comment('content.duties.' + duty.k + '.des.' + des.k + '.v')" class="el-icon-s-comment"/>
                        <el-popover placement="right" width="180" trigger="click">
                          <div style="float: left">
                            <el-button type="primary" size="mini" @click="selRelationDoc('content.duties.' + duty.k + '.des.' + des.k + '.v','doc')">文件</el-button>
                            <el-button type="primary" size="mini" @click="selRelationDoc('content.duties.' + duty.k + '.des.' + des.k + '.v','law')">法规</el-button>
                            <el-button type="primary" size="mini" @click="selRelationDoc('content.duties.' + duty.k + '.des.' + des.k + '.v','abbr')">缩略语</el-button>
                            <el-button type="primary" size="mini" v-if="duty.des.length > 1" @click="strikeOut(duty.des,idex,(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-minus"/>
                            <el-button type="primary" size="mini" v-if="idex < duty.des.length-1" @click="swap(duty.des,idex,(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-bottom"/>
                            <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(duty.des,idex-1,(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-top"/>
                          </div>
                          <el-button size="mini" slot="reference">...</el-button>
                        </el-popover>
                      </div>
                      <docItemRemarks :path="'content.duties.' + duty.k + '.des.' + des.k + '.v'"/>
                    </div>
                    <div v-if="EnOn" style="background:#FDE2E7">
                      <i v-if="!EnEditing" @click="comment('content.duties.' + duty.k + '.des.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                      <pre style="width: 748px;float: left" v-html="displayContent['content.duties.' + duty.k + '.des.' + des.k + '.v_E'] || des.v_E" v-if="!EnEditing && des.v_E">● {{des.v_E}}</pre>
                      <p v-if="EnEditing" style="width: 10px;float: left">● </p>
                      <el-input v-if="EnEditing" style="width: 748px" @input="updateDisplayContent('content.duties.' + duty.k + '.des.' + des.k + '.v_E', des.v_E)" :id="'content.duties.' + duty.k + '.des.' + des.k + '.v_E'" v-model="des.v_E" type="textarea" :autosize="{ minRows: 2}"/>
                      <div v-if="EnEditing" style="width: 15px;float: right">
                        <el-button @click="add(duty.des, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-plus"/>
                        <i @click="comment('content.duties.' + duty.k + '.des.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                        <el-popover placement="right" width="180" trigger="click">
                          <div style="float: left">
                            <el-button type="primary" size="mini" @click="selRelationDoc('content.duties.' + duty.k + '.des.' + des.k + '.v_E','doc')">文件</el-button>
                            <el-button type="primary" size="mini" @click="selRelationDoc('content.duties.' + duty.k + '.des.' + des.k + '.v_E','law')">法规</el-button>
                            <el-button type="primary" size="mini" @click="selRelationDoc('content.duties.' + duty.k + '.des.' + des.k + '.v_E','abbr')">缩略语</el-button>
                            <el-button type="primary" size="mini" v-if="duty.des.length > 1" @click="strikeOut(duty.des,idex,(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-minus"/>
                            <el-button type="primary" size="mini" v-if="idex < duty.des.length-1" @click="swap(duty.des,idex,(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-bottom"/>
                            <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(duty.des,idex-1,(content2 && content2.duties[idx]) ? content2.duties[idx].des:null)" class="el-icon-top"/>
                          </div>
                          <el-button size="mini" slot="reference">...</el-button>
                        </el-popover>
                      </div>
                      <docItemRemarks :path="'content.duties.' + duty.k + '.des.' + des.k + '.v_E'"/>
                    </div>
                    <docItemRemarks :path="'content.duties.' + duty.k + '.des.' + des.k"/>
                  </div>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.duties, idx,{role:'',role_E:'',des:[{v:'',v_E:'', k:genNewK()}], k:genNewK()},content2 ? content2.duties:null)" class="el-icon-plus"/>
                  <i @click="comment('content.duties.' + duty.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="content.duties.length > 1 && editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" v-if="content.duties.length > 1" @click="strikeOut(content.duties,idx,content2 ? content2.duties:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.duties.length-1" @click="swap(content.duties,idx,content2 ? content2.duties:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.duties,idx-1,content2 ? content2.duties:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.duties.'+ duty.k]" :key="duty.k + 'c'">
                <td colspan="2" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.duties.'+ duty.k"/>
                </td>
              </tr>
            </template>
          </table>
        </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>

    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";
import preview from "./preview.vue";

export default {
  name: "duties",

  mixins:[tabMix],

  components:{preview},

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>