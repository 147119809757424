import doc from "@/doc/doc2.vue"
import docPlan from "@/doc/docPlan.vue"
import editSop from "@/doc/editSop2.vue"
import editPolicy from "@/doc/editPolicy2.vue";
import editManual from "@/doc/editManual2.vue";
import Purpose from "@/doc/purpose.vue"
import scopes from "@/doc/scopes.vue"
import termAndAbbr from "@/doc/termAndAbbr.vue"
import annexes from "@/doc/annexes.vue"
import refFlows from "@/doc/refFlows.vue"
import laws from "@/doc/laws.vue";
import duties from "@/doc/duties.vue"
import docRolesPlan from "@/doc/docRolesPlan";
import flows from "@/doc/flows.vue"
import histories from "@/doc/histories.vue"
import principleGroups from "@/doc/principleGroups.vue";
import excutions from "@/doc/excutions.vue";
import principles from "@/doc/principles.vue";
import fix from "@/doc/fix.vue"
import docMix2 from "./docMix2";
import docEditState from "@/doc/docEditState";
import REQ from "@/app/REQ";
import {cloneObj} from "@/utils/xyhUtils";
import {DOC_ROLE, DOC_STATE, DOC_TYPE} from "@/consts";
import Vue from 'vue'
import {mapGetters, mapState} from "vuex";

function person(k){return {name: '', name_E:'',position: '',position_E:'',dept_E:'', dept: '', duty_E:'',duty: '', k,}}
function annex(k){return {name: '',name_E:'', num: '',num_E:'', k}}
function abbr(k){return {name: '',name_E:'', fullEn: '', fullCn: '', k,}}
function term(k){return {name: '',name_E:'',des_E:'', des: '', k,}}
function refLaw(k){return {name: '',name_E:'', issuer: '',issuer_E:'', num: '',num_E:'', date: '',date_E:'', k,}}
function refFlow(k){return {num: '',name: '',name_E:'', ver: '',ver_E:'', k,}}
function history(k){return {ver: '',ver_E:'',note_E:'', note: '', k,}}
function scope(k){return {name: '',name_E:'',des_E:'', des: '', k,}}

function excution(k){return {name: '',name_E:'',des_E:'', des: '',k}}

function purpose(k){return {content:'',content_E:'',k}}

export default {

    components: {
        doc,
        docPlan,
        Purpose,
        scopes,
        flows,
        fix,
        duties,
        docRolesPlan,
        termAndAbbr,
        annexes,
        refFlows,
        histories,
        editSop,
        editPolicy,
        editManual,
        principleGroups,
        excutions,
        principles,
        laws,
    },
    mixins:[docMix2],

    data() {
        return {
            tabs: [
                {name: 'doc', label: '基本信息',},
                {name: 'docPlan', label: '规划'},
                {name: 'Purpose', label: '目的', type: {sop:true, policy:true, manual:true}},
                {name: 'scopes', label: '范围', type: {sop:true, policy:true, manual:true}},
                {name: 'principleGroups', label: '一般原则', type: {sop:true},},
                {name: 'docRolesPlan', label: '角色配置', type: {sop:true, manual:true},},
                {name: 'flows', label: '流程设计', type: {sop:true},},
                {name: 'duties', label: '职责描述', type: {sop:true},},
                {name: 'principleGroups', label: '原则和规范', type: {policy:true}},
                {name: 'excutions', label: '政策执行', type: {policy:true},},
                {name: 'duties', label: '职责描述', type: {manual:true},},
                {name: 'principles', label: '要求和原则', type: {manual:true},},
                {name: 'termAndAbbr', label: '术语和缩略语', type: {sop:true, policy:true, manual:true}},
                {name: 'annexes', label: '附件', type: {sop:true, policy:true, manual:true},},
                {name: 'refFlows', label: '关联文件', type: {sop:true, policy:true, manual:true}},
                {name: 'laws', label: '关联法规', type: {sop:true, policy:true, manual:true}},
                {name: 'histories', label: '版本历史', type: {sop:true, policy:true, manual:true}},
                {name: 'editSop', label: '审阅', type: {sop:true}},
                {name: 'editPolicy', label: '审阅', type: {policy:true}},
                {name: 'editManual', label: '审阅', type: {manual:true}},
                {name: 'fix', label: '定稿'},
                {name: '...', label: '...',},
                // {name: 'finish', label: '终稿', show:true},
                // {name: 'upgrade', label: '升级', show:true},
            ],

            content:docEditState.content,
            tabN: 'doc',
        };
    },

    provide:function () {
        return {
            gainDoc:this.getDoc,
        }
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
        }),
    },

    created() {
        docEditState.clean()
    },

    methods: {
        showEllipsis(res){
            if(res.label == '...') {
                let idex = 0
                this.tabN = 'fix'
                this.tabs.forEach((tab, idx) => {
                    if (tab.show) tab.show = false
                    if (tab.label == '...') idex = idx
                })
                this.tabs.splice(idex, 1)
            }
        },

        getDoc(){
            let {tabs} = this
            this.req2Data(REQ.doc, this.id, {doc: 'doc',}, ({docStaffs, prjt, doc, prjtStaffs, staffs}) => {
                docEditState.docStaffs = docStaffs
                docEditState.prjtStaffs = prjtStaffs
                docEditState.staffs = staffs
                doc.prjtN = prjt.name
                doc.url = XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + doc.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
                if(!doc.state) doc.state = DOC_STATE.remarking
                if(!doc.type) doc.type = DOC_TYPE.sop
                if(doc.design){
                    Vue.set(docEditState,'design',doc.design)
                }else {
                    Vue.set(docEditState, 'design', {
                        deptsRecv: [], deployRoles:[],
                    })
                }
                if (doc.content) {
                    docEditState.editing = false
                    if(doc.kMax) docEditState.kMax = doc.kMax

                    if(!doc.content.annexes) doc.content.annexes = [annex(this.genNewK()),]
                    if(doc.type == DOC_TYPE.sop){
                         doc.content.flows.forEach(flow => flow.steps.forEach(step => {
                             if(!step.roles) {
                                 Vue.set(step, 'roles', [])
                                 if (step.role) {
                                     let role_C = step.role.split(/[,，/&]/)
                                     role_C.map((it, idx) => {
                                         if (step.roles[idx]) {
                                             Vue.set(step.roles[idx], 'role', it)
                                         } else {
                                             Vue.set(step.roles, idx, {role: it, role_E: '', k: this.genNewK()})
                                         }
                                     })
                                 }
                                 if (step.role_E) {
                                     let role_E = step.role_E.split(/[,，/&]/)
                                     role_E.map((it, idx) => {
                                         if (step.roles[idx]) {
                                             Vue.set(step.roles[idx], 'role_E', it)
                                         } else {
                                             Vue.set(step.roles, idx, {role: '', role_E: it, k: this.genNewK()})
                                         }
                                     })
                                 }
                                 step.roles.push({role: '', role_E: '', k: this.genNewK()})
                             }
                         }))

                        if(doc.content.principles){
                            if (!doc.content.principleGroups) {
                                doc.content.principleGroups = [{name: '', principles: doc.content.principles}]
                            }
                            delete doc.content.principles
                        }
                    }

                    if(doc.type == DOC_TYPE.policy || doc.type == DOC_TYPE.manual){
                        if (!doc.content.refLaws) {
                            doc.content.refLaws = [
                                {name: '',name_E:'', issuer: '',issuer_E:'', num: '',num_E:'', date: '',date_E:'', k: 109},
                            ]
                        }
                        if (!doc.content.refFlows) {
                            doc.content.refFlows = [
                                {num: '',num_E:'', name: '',name_E:'', ver: '',ver_E:'', k: 150},
                            ]
                        }
                    }

                    this.checkToInitK(doc.content)
                    Vue.set(docEditState,'content',doc.content)
                    Vue.set(docEditState,'content2', cloneObj(doc.content))
                }else {
                    if(doc.type == DOC_TYPE.sop){
                        Vue.set(docEditState,'content' ,{
                            authors: [person(100),],
                            auditors: [person(101),],
                            authorizers: [person(102),],
                            duties: [{role: '',role_E:'', des: [{k: 103, v: '',v_E:'',}], k: 104,},],
                            principleGroups: [{name: '', name_E:'',principles: [{name: '',name_E:'', des: [{k: 105, v: '',v_E:'',}], k: 106},], k: 107}],
                            flows: [{title: '', title_E:'',steps: [{role: '', role_E:'',content: [{k: 108, v: '',v_E:''}], k: 109},], k: 110}],
                            refLaws: [refLaw(111),],
                            refFlows: [refFlow(112),],
                            abbrs: [abbr(113),],
                            terms: [term(114),],
                            annexes: [annex(115),],
                            histories: [history(116),],
                            scopes: [scope(118),],
                            scopeNote: '',
                            scopeNote_E:'',
                            purpose: '',
                            purposes_E:'',
                            sDate: '',
                            sDate_E:'',
                            issueDate: '',
                            issueDate_E:'',
                            deptIssue: '',
                            deptIssue_E:'',
                            deptsRecv: '',
                            deptsRecv_E: '',
                        })
                    }else if(doc.type == DOC_TYPE.policy){
                        Vue.set(docEditState,'content',{
                            authors: [person(100),],
                            auditors: [person(101),],
                            authorizers: [person(102),],
                            principleGroups: [{name: '',name_E:'', principles: [{name: '', name_E:'',des: [{k: 103, v: '',v_E:'',}], k: 104},], k: 105}],
                            excutions: [excution(106),],
                            abbrs: [abbr(107),],
                            terms: [term(108),],
                            refLaws: [refLaw(109),],
                            refFlows: [refFlow(110),],
                            histories: [history(111),],
                            scopes: [scope(112)],
                            annexes: [annex(115),],
                            scopeNote: '',
                            scopeNote_E:'',
                            purpose: '',
                            purpose_E:'',
                            sDate: '',
                            sDate_E: '',
                            issueDate: '',
                            issueDate_E: '',
                            deptEdt: '',
                            deptEdt_E:'',
                            deptsRecv: '',
                            deptsRecv_E:'',
                        })
                    }else if (doc.type == DOC_TYPE.manual){
                        Vue.set(docEditState,'content',{
                            authors: [person(100),],
                            auditors: [person(101),],
                            authorizers: [person(102),],
                            duties: [{role: '',role_E:'', des: [{k: 103, v: '',v_E:'',}], k: 104,},],
                            principles: [{name: '',name_E:'', des: [{v_E:'',v:'',k:105}], k: 106},],
                            abbrs: [abbr(107),],
                            terms: [term(108),],
                            refLaws: [refLaw(109),],
                            refFlows: [refFlow(110),],
                            histories: [history(111),],
                            scopes: [scope(112),],
                            scopeNote: '',
                            scopeNote_E:'',
                            purposes: [purpose(113),],
                            annexes: [annex(115),],
                            sDate: '',
                            sDate_E: '',
                            deptEdt: '',
                            deptEdt_E: '',
                            deptsRecv: '',
                            deptsRecv_E:'',
                        })
                    }
                }

                if(prjtStaffs.find(it=>it.role == DOC_ROLE.PM && it.staffId == this.staffId)) {
                    this.amIPM = true
                    docEditState.amIPM = true
                }
                if(docStaffs.find(it=>it.role == DOC_ROLE.author && it.staffId == this.staffId)) {
                    this.amIAuthor = true
                    docEditState.amIAuthor = true
                }
                if(docStaffs.find(it=>it.role == DOC_ROLE.auditor && it.staffId == this.staffId)) {
                    this.amIAuditor = true
                    docEditState.amIAuditor = true
                }
                if(docStaffs.find(it=>it.role == DOC_ROLE.translator && it.staffId == this.staffId)) {
                    this.amITranslator = true
                    docEditState.amITranslator = true
                }
                if(!this.amIAuthor) docEditState.editing = false
                if(doc.state == DOC_STATE.fixed) docEditState.editing = false
                Vue.set(docEditState,'doc', doc)
            })
        },
    }
};