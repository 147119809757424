import tabMix from "./tabMix";
import preview from "./preview.vue";

export default {
    name: "purpose",

    mixins:[tabMix],

    components:{preview},

    data() {
        return {
        }
    },

    methods: {

    },

}