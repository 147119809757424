<template>
  <div>
    <div id="tabTop" style="margin-top: 118px">
      <el-select size="mini" style="width: 90px;line-height: 28px;margin: 0 5px;" v-model="lang" @change="onSetLanMode" placeholder="语言">
        <el-option v-for="item in LanguageModels" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <template v-if="doc.state != DOC_STATE.fixed">
        <el-button style="border: 0;" v-if="(amIAuthor || amIPM ) && lanMode != LANGUAGE_MODEL.Cn2En" :style="editing ? {}:{'background': '#8c939d'}" type="primary" size="mini" @click="onSetEditing">编辑</el-button>
        <el-button type="primary" v-if="amIAuthor || amIPM || amITranslator" size="mini" @click="onSave">保存</el-button>
        <el-button type="primary" v-if="CnOn && EnOn" size="mini" @click="onTranslate()">翻译</el-button>
        <el-button style="border: 0" type="primary" size="mini" @click="showDlg('smsNotiDlg')">提醒</el-button>
        <el-button style="border: 0" type="primary" size="mini" @click="previewRefFlow(doc.id, true)">预览</el-button>
        <el-button style="border: 0" type="primary" size="mini" @click="downloadFile(doc.num+'_'+doc.name,doc.url)">下载</el-button>
      </template>
      <el-button style="border: 0" :style="showComment ? {}:{'background': '#8c939d'}" type="primary" size="mini" @click="onSetShowComment">{{ showComment ? '关闭':'显示'}}评论</el-button>
    </div>
    <smsNotiDlg ref="smsNotiDlg" :pmStaffs="pmStaffs"  :authorStaffs="authorStaffs" :auditorStaffs="auditorStaffs" :docId="doc.id" :staffId="staffId" :amIAuthor="amIAuthor" :amIAuditor="amIAuditor"/>
  </div>
</template>

<script>
import docMix2 from "./docMix2";
import navTopMix from "@/doc/navTopMix";
import docEditState from "@/doc/docEditState";

export default {
  name: "navTop",

  mixins:[docMix2, navTopMix],

  methods: {
    getDoc(){
      this.screenStaffs(docEditState.docStaffs, docEditState.prjtStaffs, docEditState.staffs)
    },
  }
}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>