<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div style="padding-top: 20px">
        <h4>流程<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.flows')"/></h4>
        <preview/>
        <docItemRemarks :path="'content.flows'"/>
        <el-form-item v-for="(flow, index) in content.flows" :key="flow.k">
          <div style="margin-top: 10px" :class="(content2 && !content2.flows[index]) ? 'modifyTag':'divUnmark'">
            <h3 style="margin:0 20px;line-height:48px;float: left">流程{{convertToChinaNum(index + 1)}}：名称:</h3>
            <docItem :valCn.sync="flow.title" :valEn.sync="flow.title_E" :path="'content.flows.' + flow.k + '.title'" :w="500"
                     :modifiedCn="content2 && content2.flows[index] && flow.title != content2.flows[index].title"
                     :modifiedEn="content2 && content2.flows[index] && flow.title_E != content2.flows[index].title_E"/>
            <table class="table">
              <tr>
                <th>步骤</th>
                <th>角色</th>
                <th>流程</th>
              </tr>
              <template v-for="(step,idx) in flow.steps">
                <tr :key="step.k">
                  <td style="width: 5%" :class="(content2 && content2.flows[index] && !content2.flows[index].steps[idx]) ? 'modifyTag':'tdUnmark'">{{ idx + 1 }}</td>
                  <td style="width: 15%" :class="(content2 && content2.flows[index] && !content2.flows[index].steps[idx]) ? 'modifyTag':'tdUnmark'">
                    <div v-if="CnOn">
                      <el-tag v-for="(tag, idex) in step.roles" v-if="idex < step.roles.length-1" :disable-transitions="false">
                        {{tag.role}}<span v-if="CnEditing" class="el-icon-close" @click="strikeOut(step.roles, idex, null)"/>
                      </el-tag>
                      <el-select v-if="CnEditing" v-model="role" allow-create filterable placeholder="检索一下"
                                 @change="r=>{add(step.roles, step.roles.length-2, {role:r.name, role_E:r.name_E, k:genNewK(), refId:r.id}, null);role = ''}">
                        <el-option v-for="r in design.deployRoles" :label="r.name" :value="r"></el-option>
                      </el-select>
                      <i @click="comment('content.flows.' + flow.k + '.steps.' + step.k + '.role')" class="el-icon-s-comment"/>
                      <docItemRemarks :path="'content.flows.' + flow.k + '.steps.' + step.k + '.role'"/>
                    </div>
                    <div v-if="EnOn" style="background:#FDE2E7">
                      <el-tag v-for="(tag, idex) in step.roles" v-if="idex < step.roles.length-1" :disable-transitions="false">
                        {{tag.role_E}}<span v-if="EnEditing" class="el-icon-close" @click="strikeOut(step.roles, idex, null)"/>
                      </el-tag>
                      <el-select v-if="EnEditing" v-model="role_E" allow-create filterable placeholder="检索一下"
                                 @change="r=>add(step.roles, step.roles.length-2, {role:r.name, role_E:r.name_E, k:genNewK(), refId:r.id}, null)">
                        <el-option v-for="r in design.deployRoles" :label="r.name_E" :value="r"></el-option>
                      </el-select>
                      <i @click="comment('content.flows.' + flow.k + '.steps.' + step.k + '.role_E')" class="el-icon-s-comment"/>
                      <docItemRemarks :path="'content.flows.' + flow.k + '.steps.' + step.k + '.role_E'"/>
                    </div>
                  </td>
                  <td :class="(content2 && content2.flows[index] && !content2.flows[index].steps[idx]) ? 'modifyTag':'tdUnmark'">
                    <div v-for="(des,idex) in step.content" :key="des.k">
                      <div v-if="CnOn">
                        <i v-if="!CnEditing" @click="comment('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v')" class="el-icon-s-comment"/>
                        <pre v-if="!CnEditing && des.v" style="float: left;width: 757px" v-html="displayContent['content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v']"
                             :class="(content2 && content2.flows[index] && content2.flows[index].steps[idx] && content2.flows[index].steps[idx].content[idex] && des.v != content2.flows[index].steps[idx].content[idex].v) ? 'modifyTag':'pUnmark'">● {{des.v}}</pre>
                        <p v-if="CnEditing" style="width: 10px;float: left">● </p>
                        <el-input v-if="CnEditing" style="width: 747px" @input="updateDisplayContent('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v', des.v)"
                                  :id="'content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v'" v-model="step.content[idex].v" type="textarea" :autosize="{ minRows: 2}"/>
                        <div v-if="CnEditing" style="width: 15px;float: right">
                          <el-button @click="add(step.content, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-plus"/>
                          <i @click="comment('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v')" class="el-icon-s-comment"/>
                          <el-popover placement="right" width="180" trigger="click">
                            <div style="float: left">
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v','doc')">文件</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v','law')">法规</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v', 'abbr')">缩略语</el-button>
                              <el-button type="primary" size="mini" v-if="step.content.length > 1" @click="strikeOut(step.content,idex,(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-minus"/>
                              <el-button type="primary" size="mini" v-if="idex < step.content.length-1" @click="swap(step.content,idex,(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-bottom"/>
                              <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(step.content,idex-1,(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-top"/>
                            </div>
                            <el-button size="mini" slot="reference">...</el-button>
                          </el-popover>
                        </div>
                        <docItemRemarks :path="'content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v'"/>
                      </div>
                      <div v-if="EnOn" style="background:#FDE2E7">
                        <i v-if="!EnEditing" @click="comment('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                        <pre style="width: 747px;float: left" v-if="!EnEditing && des.v_E" v-html="displayContent['content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E'] || des.v_E">● {{des.v_E}}</pre>
                        <p v-if="EnEditing" style="width: 10px;float: left">● </p>
                        <el-input v-if="EnEditing" style="width: 747px" @input="updateDisplayContent('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E', des.v_E)"
                                  :id="'content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E'" v-model="des.v_E" type="textarea" :autosize="{ minRows: 2}"/>
                        <div v-if="EnEditing" style="width: 15px;float: right">
                          <el-button @click="add(step.content, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-plus"/>
                          <i @click="comment('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                          <el-popover placement="right" width="180" trigger="click">
                            <div style="float: left">
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E','doc')">文件</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E','law')">法规</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E','abbr')">缩略语</el-button>
                              <el-button type="primary" size="mini" v-if="step.content.length > 1" @click="strikeOut(step.content,idex,(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-minus"/>
                              <el-button type="primary" size="mini" v-if="idex < step.content.length-1" @click="swap(step.content,idex,(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-bottom"/>
                              <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(step.content,idex-1,(content2 && content2.flows[index] && content2.flows[index].steps[idx]) ? content2.flows[index].steps[idx].content:null)" class="el-icon-top"/>
                            </div>
                            <el-button size="mini" slot="reference">...</el-button>
                          </el-popover>
                        </div>
                        <docItemRemarks :path="'content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k + '.v_E'"/>
                      </div>
                      <docItemRemarks :path="'content.flows.' + flow.k + '.steps.' + step.k + '.content.' + des.k"/>
                    </div>
                  </td>
                  <td class="buttonColumn">
                    <el-button v-if="editing" @click="add(flow.steps, idx,{roles:[{role: '', role_E: '', k: genNewK()}],content:[{v:'',v_E:'', k:genNewK()}], k:genNewK()},(content2 && content2.flows[index]) ? content2.flows[index].steps:null)" class="el-icon-plus"/>
                    <i @click="comment('content.flows.' + flow.k + '.steps.' + step.k)" class="el-icon-s-comment"/>
                    <el-popover v-if="flow.steps.length > 1 && editing" placement="right" width="180" trigger="click">
                      <div style="float: left">
                        <el-button type="primary" size="mini" v-if="flow.steps.length > 1" @click="strikeOut(flow.steps,idx,(content2 && content2.flows[index]) ? content2.flows[index].steps:null)" class="el-icon-minus"/>
                        <el-button type="primary" size="mini" v-if="idx < flow.steps.length-1" @click="swap(flow.steps,idx,(content2 && content2.flows[index]) ? content2.flows[index].steps:null)" class="el-icon-bottom"/>
                        <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(flow.steps,idx-1,(content2 && content2.flows[index]) ? content2.flows[index].steps:null)" class="el-icon-top"/>
                      </div>
                      <el-button size="mini" slot="reference">...</el-button>
                    </el-popover>
                  </td>
                </tr>
                <tr v-if="showComment && remarksMap['content.flows.' + flow.k + '.steps.' + step.k]" :key="step.k + 'c'">
                  <td colspan="3" class="rowRemarkBorder">
                    <docItemRemarks :path="'content.flows.' + flow.k + '.steps.' + step.k"/>
                  </td>
                </tr>
              </template>
            </table>
            <div v-if="editing" style="height: 10px"></div>
            <i style="float: right;margin: 0 15px 0 10px;color: #0D8FBF;padding: 0 5px 0 5px" @click="comment('content.flows' + flow.k)" class="el-icon-s-comment"/>
            <el-button v-if="editing" type="mini" style="float: right;margin: 0 15px 0 10px;padding: 0 5px 0 5px" @click="add(content.flows, index, {title: '',title_E:'', steps: [{roles:[{role: '', role_E: '', k: genNewK()}], content: [{v:'',v_E:'', k:genNewK()}], k:genNewK()}], k:genNewK()},content2 ? content2.flows:null)" class="el-icon-plus"/>
            <el-popover v-if="content.flows.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.flows.length > 1" @click="strikeOut(content.flows,index,content2 ? content2.flows:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="index < content.flows.length-1" @click="swap(content.flows,index,content2 ? content2.flows:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="index > 0" @click="swap(content.flows,index-1,content2 ? content2.flows:null)" class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference" style="float: right;margin-left: 10px;padding: 0 5px 0 5px">...</el-button>
            </el-popover>
          </div>
          <docItemRemarks :path="'content.flows' + flow.k"/>
        </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";
import preview from "./preview.vue";

export default {
  name: "flow",

  mixins:[tabMix],

  components:{preview},

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>