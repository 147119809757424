<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div>
        <h3 style="padding-bottom: 10px">修订历史<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.histories')"/></h3>
        <docItemRemarks :path="'content.histories'"/>
        <el-form-item>
          <table class="table">
            <tr>
              <th>版本号</th>
              <th>修订说明</th>
            </tr>
            <template v-for="(history,idx) in content.histories">
              <tr :key="history.k">
                <td  style="width: 30%" :class="(content2 && !content2.histories[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput :editing="editing" :val.sync="history.ver" :path="'content.histories.' + history.k + '.ver'"
                                 :modified="content2 && content2.histories[idx] && history.ver !=content2.histories[idx].ver" :w="285"/>
                </td>
                <td :class="(content2 && !content2.histories[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="history.note" :valEn.sync="history.note_E" :path="'content.histories.' + history.k + '.note'" :w="670" :type="'text'"
                           :modifiedCn="content2 && content2.histories[idx] && history.note !=content2.histories[idx].note"
                           :modifiedEn="content2 && content2.histories[idx] && history.note_E !=content2.histories[idx].note_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.histories, idx,History(), content2 ? content2.histories:null)" class="el-icon-plus"/>
                  <i @click="comment('content.histories.' + history.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="content.histories.length > 1 && editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" v-if="content.histories.length > 1" @click="strikeOut(content.histories,idx,content2 ? content2.histories:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.histories.length-1" @click="swap(content.histories,idx,content2 ? content2.histories:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.histories,idx-1,content2 ? content2.histories:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.histories.' + history.k]" :key="history.k + 'c'">
                <td colspan="2" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.histories.' + history.k"/>
                </td>
              </tr>
            </template>
          </table>
        </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "histories",

  mixins:[tabMix],

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>