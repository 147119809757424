import REQ from '@/app/REQ'
import editAnnexDlg from "@/dialg/editAnnexDlg.vue"
import addDocPrjtStaffDlg from "@/dialg/addDocPrjtStaffDlg.vue"
import {mapGetters, mapState} from "vuex";
import {genMap, upWFoundById} from "@/utils/xyhUtils";
import {DOC_ROLE, DOC_STATE, DOC_TYPE} from "@/consts";
import docOptions from "./docOptions"
import replaceDocFileDlg from "@/doc/replaceDocFileDlg.vue"
import {wordViewUrl} from "@/doc/consts"
import editMaterialDlg from "@/doc/editMaterialDlg.vue"
import {mongoIdTime} from "../utils/dbUtils"
import docEditState from "@/doc/docEditState";
import docMix2 from "@/doc/docMix2";


const {DocPathsSMap} = docOptions

export default {
    name: 'docPlan',
    props:['id'],
    components:{editAnnexDlg, addDocPrjtStaffDlg, replaceDocFileDlg,editMaterialDlg},
    inject:['gainDoc',],

    mixins:[docMix2],

    data() {
        return {
            docPaths:DocPathsSMap,
            doc: {},
            annexes:[],
            tplt:{},
            fileType:'',
            fileUrl: '',
            docAnnex:null,
            docStaffs:[],
            amIAuthor:docEditState.amIAuthor,
            amIPM:docEditState.amIPM,
            switchDlg:false,
            stateN:'',
            dept:{},
        }
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
            myId: ({curStaff}) => curStaff?.id,
        }),
        ...mapGetters(['amIAdmin']),
    },
    created() {
        this.getDoc()
    },

    methods: {
        getDoc() {
            this.req2Data(REQ.doc, {id:this.id},null, ({docStaffs,staffs,doc,prjtStaffs})=>{
                if(!doc.state) doc.state = DOC_STATE.remarking
                if(this.tplt) this.tplt.name = doc.tpltId ? this.tplt.name:this.tplt.name + '(项目默认)'
                upWFoundById(docStaffs, staffs, 'staffId', (docStaff, staff) => docStaff.name = staff.name)
                if(doc.lastFixT || doc.fstFixT) doc.fixDate = new Date(doc.lastFixT ? doc.lastFixT:doc.fstFixT).YMDFmt()
                if(!doc.materials) doc.materials = []
                upWFoundById(doc.materials, staffs, 'adderId', (material, staff) => material.adderN = staff.name)
                doc.url = XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + doc.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
                if(!doc.type) doc.type = DOC_TYPE.sop
                doc.createDate = mongoIdTime(doc.id).YMDHMFmt()
                if(doc.lastIssueT)doc.issueDate = new Date(doc.lastIssueT).YMDFmt()
            })
        },

        editDocDate(text, title, type){
            let {id, name,name_E, num, ver, tpltId, planFixDate, planIssueDate, planDate} = this.doc
            let doc = {id, name,name_E, num, ver, tpltId, planFixDate, planIssueDate, planDate}
            this.editDate(text, title, str=>{
                doc[type] = str
                this.req2Data(REQ.upDoc, doc, null, res=> {
                    docEditState.doc[type] = res[type]
                    this.getDoc()
                })
            } )
        },

        editDocAnnex(docAnnex){
            this.docAnnex = docAnnex
            this.showDlg('editAnnexDlg')
        },

        removeDocStaff(docStaffId){
            this.req2Data(REQ.rmDocStaff,{docStaffId},{},()=>this.getDoc())
        },

        addMaterial(){
            this.showDlg('editMaterialDlg')
        },
    },

}
