<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div>
        <h3 style="padding-bottom: 10px">政策执行<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.excutions')"/></h3>
        <preview/>
        <docItemRemarks :path="'content.excutions'"/>
        <el-form-item>
          <table class="table">
            <tr>
              <th>名称</th>
              <th>描述</th>
            </tr>
            <template v-for="(excution,idx) in content.excutions">
              <tr :key="excution.k">
                <td style="width: 30%" :class="(content2 && !content2.excutions[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="excution.name" :valEn.sync="excution.name_E" :path="'content.excutions.' + excution.k + '.name'" :w="285"
                           :modifiedCn="content2 && content2.excutions[idx] && excution.name !=content2.excutions[idx].name"
                           :modifiedEn="content2 && content2.excutions[idx] && excution.name_E !=content2.excutions[idx].name_E"/>
                </td>
                <td :class="(content2 && !content2.excutions[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="excution.des" :valEn.sync="excution.des_E" :path="'content.excutions.' + excution.k + '.des'" :w="666" :type="'text'"
                           :modifiedCn="content2 && content2.excutions[idx] && excution.des !=content2.excutions[idx].des" :relation="'doc'"
                           :modifiedEn="content2 && content2.excutions[idx] && excution.des_E !=content2.excutions[idx].des_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.excutions, idx,Excution(),content2 ? content2.excutions:null)" class="el-icon-plus"/>
                  <i @click="comment('content.excutions.' + idx)" class="el-icon-s-comment"/>
                  <el-popover v-if="content.excutions.length > 1 && editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" v-if="content.excutions.length > 1" @click="strikeOut(content.excutions,idx,content2 ? content2.excutions:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.excutions.length-1" @click="swap(content.excutions,idx,content2 ? content2.excutions:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.excutions,idx-1,content2 ? content2.excutions:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.excutions.' + idx]" :key="excution.k + 'c'">
                <td colspan="2" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.excutions.' + idx"/>
                </td>
              </tr>
            </template>
          </table>
        </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";
import preview from "./preview.vue";

export default {
  name: "excutions",

  mixins:[tabMix],

  components:{preview},

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>