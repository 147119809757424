import tabMix from "./tabMix";
import {genMap} from "@/utils/xyhUtils";
import docEditState from "@/doc/docEditState";
import preview from "./preview.vue";

export default {
    name: "scopes",

    mixins:[tabMix],

    components:{preview},

    data() {
        return {
            filterDepts: [],
            filterRoles: [],
            deptRefId: '',
            deptIdx: '',
        }
    },

    methods: {
        selDeptsRecv() {
            let {depts, design} = this
            let deptsRecvMap = genMap(design.deptsRecv, 'refId')
            this.filterDepts = depts.filter(dept => !deptsRecvMap[dept.id])
            this.showDlg('selDeptsDlg')
        },

        addDeptsRecv(deptIds){
            let {depts, design} = this
            let deptsMap = genMap(depts)
            deptIds.forEach(id => this.add(design.deptsRecv, design.deptsRecv.length,
                {dept: id == 'allCompany' ? '全公司':deptsMap[id].name, dept_E: id == 'allCompany' ? 'allCompany':deptsMap[id].name_E, roles: [], k:this.genNewK(), refId: id}))
        },

        selRoles(deptId, deptIdx){
            this.deptRefId = deptId
            this.deptIdx = deptIdx
            let {design, deptRole} = this
            let rolesMap = genMap(design.deptsRecv[deptIdx].roles, 'refId')
            if(deptRole[deptId]){
                this.filterRoles = deptRole[deptId].filter(role => !rolesMap[role.id])
            }else if (deptRole.null){
                this.filterRoles = deptRole.null.filter(role => !rolesMap[role.id])
            }

            this.showDlg('selRolesDlg')
        },

        addRoles(roleIds){
            let {deptIdx, design, roles} = this
            let rolesMap = genMap(roles)
            roleIds.forEach(id => this.add(design.deptsRecv[deptIdx].roles, design.deptsRecv[deptIdx].roles.length,
                {role: rolesMap[id].name, role_E: rolesMap[id].name_E, k:this.genNewK(), refId: id}))
        },

        autoLinkdeptsRecv(){
            let {deptsRecv} = this.design
            let deptsRecvStr = ''
            deptsRecv.forEach(it=>{
                if(it.refId){
                    deptsRecvStr = deptsRecvStr + it.dept + ':'
                    it.roles.forEach((role,idx) =>{
                        deptsRecvStr = deptsRecvStr + role.role + (idx+1 == it.roles.length ? '':'、')
                    })
                    deptsRecvStr = deptsRecvStr + '；'
                }
            })
            docEditState.content.deptsRecv = deptsRecvStr
        },

    },

}