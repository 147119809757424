import { render, staticRenderFns } from "./laws.vue?vue&type=template&id=6a2438de&scoped=true&"
import script from "./laws.vue?vue&type=script&lang=js&"
export * from "./laws.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=6a2438de&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2438de",
  null
  
)

export default component.exports