<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div>
        <h3>{{ doc.type == DOC_TYPE.sop ? '一般原则或要求':'原则和规范' }}<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.principleGroups')"/></h3>
        <preview/>
        <docItemRemarks :path="'content.principleGroups'"/>
        <el-form-item v-for="(principleGroup, index) in content.principleGroups" :key="principleGroup.k">
          <div style="margin-top: 10px" :class="(content2 && !content2.principleGroups[index]) ? 'modifyTag':'divUnmark'">
            <h3 style="margin:0 20px;line-height:48px;float: left" v-if="doc.type != DOC_TYPE.sop || content.principleGroups.length >1">名称:</h3>
            <docItem v-if="doc.type != DOC_TYPE.sop || content.principleGroups.length >1" :valCn.sync="principleGroup.name" :valEn.sync="principleGroup.name_E" :path="'content.principleGroups.' + principleGroup.k + '.name'" :w="500"
                     :modifiedCn="content2 && content2.principleGroups[index] && principleGroup.name != content2.principleGroups[index].name" :type="'text'"
                     :modifiedEn="content2 && content2.principleGroups[index] && principleGroup.name_E != content2.principleGroups[index].name_E"/>
            <table class="table">
              <tr>
                <th>{{ doc.type == DOC_TYPE.sop ? '原则或要求':'规范内容' }}</th>
                <th>{{ doc.type == DOC_TYPE.sop ? '描述':'规范列表' }}</th>
              </tr>
              <template v-for="(principle,idx) in principleGroup.principles">
                <tr :key="principle.k">
                  <td style="width: 30%"  :class="(content2 && content2.principleGroups[index] && !content2.principleGroups[index].principles[idx]) ? 'modifyTag':'tdUnmark'">
                    <docItem :valCn.sync="principle.name" :valEn.sync="principle.name_E" :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.name'" :w="283"
                             :modifiedCn="content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx] && principle.name != content2.principleGroups[index].principles[idx].name"
                             :modifiedEn="content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx] && principle.name_E != content2.principleGroups[index].principles[idx].name_E"/>
                  </td>
                  <td style="width: 69%"  :class="(content2 && content2.principleGroups[index] && !content2.principleGroups[index].principles[idx]) ? 'modifyTag':'tdUnmark'">
                    <div v-for="(des,idex) in principle.des" :key="des.k">
                      <div v-if="CnOn">
                        <i v-if="!CnEditing" @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v')" class="el-icon-s-comment"/>
                        <pre v-if="!CnEditing && des.v" v-html="displayContent['content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v']"
                             :class="(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx] && content2.principleGroups[index].principles[idx].des[idex] && des.v != content2.principleGroups[index].principles[idx].des[idex].v) ? 'modifyTag':'pUnmark'">● {{des.v}}</pre>
                        <p v-if="CnEditing" style="width: 10px;float: left">● </p>
                        <el-input v-if="CnEditing" style="width: 647px" @input="updateDisplayContent('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v', des.v)"
                                  :id="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v'" v-model="principle.des[idex].v" type="textarea" rows="2" :autosize="{ minRows: 2}"/>
                        <div v-if="CnEditing" style="width: 15px;float: right">
                          <el-button @click="add(principle.des, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-plus"/>
                          <i @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v')" class="el-icon-s-comment"/>
                          <el-popover placement="right" width="180" trigger="click">
                            <div style="float: left">
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v','doc')">文件</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v','law')">法规</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v','abbr')">缩略语</el-button>
                              <el-button type="primary" size="mini" v-if="principle.des.length > 1" @click="strikeOut(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-minus"/>
                              <el-button type="primary" size="mini" v-if="idex < principle.des.length-1" @click="swap(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-bottom"/>
                              <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(principle.des,idex-1,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-top"/>
                            </div>
                            <el-button size="mini" slot="reference">...</el-button>
                          </el-popover>
                        </div>
                        <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v'"/>
                      </div>
                      <div v-if="EnOn" style="background:#FDE2E7">
                        <i v-if="!EnEditing" @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                        <pre v-if="!EnEditing && des.v_E" v-html="displayContent['content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E'] || des.v_E">● {{des.v_E}}</pre>
                        <p v-if="EnEditing" style="width: 10px;float: left">● </p>
                        <el-input v-if="EnEditing" style="width: 647px" @input="updateDisplayContent('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E', des.v_E)"
                                  :id="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E'"  v-model="principle.des[idex].v_E" type="textarea" :autosize="{ minRows: 2}"/>
                        <div v-if="EnEditing" style="width: 15px;float: right">
                          <el-button @click="add(principle.des, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-plus"/>
                          <i @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                          <el-popover placement="right" width="180" trigger="click">
                            <div style="float: left">
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E','doc')">文件</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E','law')">法规</el-button>
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E','abbr')">缩略语</el-button>
                              <el-button type="primary" size="mini" v-if="principle.des.length > 1" @click="strikeOut(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-minus"/>
                              <el-button type="primary" size="mini" v-if="idex < principle.des.length-1" @click="swap(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-bottom"/>
                              <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(principle.des,idex-1,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-top"/>
                            </div>
                            <el-button size="mini" slot="reference">...</el-button>
                          </el-popover>
                        </div>
                        <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E'"/>
                      </div>
                      <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k"/>
                    </div>
                  </td>
                  <td class="buttonColumn">
                    <el-button v-if="editing" @click="add(principleGroup.principles, idx,{name:'',name_E:'',des:[{v:'', k:genNewK()}], k:genNewK()},(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-plus"/>
                    <i @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k)" class="el-icon-s-comment"/>
                    <el-popover v-if="principleGroup.principles.length > 1 && editing" placement="right" width="180" trigger="click">
                      <div style="float: left">
                        <el-button type="primary" size="mini" v-if="principleGroup.principles.length > 1" @click="strikeOut(principleGroup.principles,idx,(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-minus"/>
                        <el-button type="primary" size="mini" v-if="idx < principleGroup.principles.length-1" @click="swap(principleGroup.principles,idx,(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-bottom"/>
                        <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(principleGroup.principles,idx-1,(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-top"/>
                      </div>
                      <el-button size="mini" slot="reference">...</el-button>
                    </el-popover>
                  </td>
                </tr>
                <tr v-if="showComment && remarksMap['content.principleGroups.' + principleGroup.k + '.principles.' + principle.k]" :key="principle.k + 'c'">
                  <td colspan="2" class="rowRemarkBorder">
                    <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k"/>
                  </td>
                </tr>
              </template>
            </table>
            <div v-if="editing" style="height: 10px"></div>
            <el-button v-if="editing" type="mini" style="float: right;margin: 0 15px 0 10px;padding: 0 5px 0 5px" @click="add(content.principleGroups, index, {name: '',name_E:'', principles: [{name: '', name_E:'',des: [{v:'', v_E:'',k:genNewK()}], k:genNewK()}], k:genNewK()},content2 ? content2.principleGroups:null)" class="el-icon-plus"/>
            <i style="float: right;margin: 0 15px 0 10px;color: #0D8FBF;padding: 0 5px 0 5px" @click="comment('content.principleGroups.' + principleGroup.k)" class="el-icon-s-comment"/>
            <el-popover v-if="content.principleGroups.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.principleGroups.length > 1" @click="strikeOut(content.principleGroups,index,content2 ? content2.principleGroups:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="index < content.principleGroups.length-1" @click="swap(content.principleGroups,index,content2 ? content2.principleGroups:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="index > 0" @click="swap(content.principleGroups,index-1,content2 ? content2.principleGroups:null)" class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference" style="float: right;margin-left: 10px;padding: 0 5px 0 5px">...</el-button>
            </el-popover>
          </div>
          <docItemRemarks :path="'content.principleGroups.' + principleGroup.k"/>
        </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";
import preview from "./preview.vue";

export default {
  name: "principleGroups",

  mixins:[tabMix],

  components:{preview},

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>