import { render, staticRenderFns } from "./flows.vue?vue&type=template&id=5a843fa8&scoped=true&"
import script from "./flows.vue?vue&type=script&lang=js&"
export * from "./flows.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=5a843fa8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a843fa8",
  null
  
)

export default component.exports