import addGroupStaffDlg from "./addGroupStaffDlg.vue"
import elDragDialog from "@/directive/el-drag-dialog";
import dlgFoot from "@/generalComs/dlgFoot.vue"
import docItemRemarks from "./docItemRemarks.vue";
import currencyInput from "./currencyInput.vue";
import docItem from "./docItem.vue";
import docMix2 from "./docMix2";
import navTop from "./navTop";
import docEditState from "@/doc/docEditState";
import {mapState} from "vuex";
import {DOC_TYPE, LANGUAGE_MODEL} from "@/consts";
import editDocDlg from '@/dialg/editDocDlg.vue'
import {cloneObj} from "@/utils/xyhUtils";


export default {
    name: "tabMix",
    components: {
        dlgFoot,
        editDocDlg,
        addGroupStaffDlg,
        docItemRemarks,
        currencyInput,
        docItem,
        navTop,
    },
    directives: { elDragDialog },
    mixins:[docMix2],

    data() {
        return {
        }
    },

    methods: {
        getDoc(){
            this.getDocPrjt()
            this.getRemarks()
            this.screenStaffs(docEditState.docStaffs, docEditState.prjtStaffs, docEditState.staffs)
            this.getDocs()
        },

        addAnnex() {
            this.showDlg('editDocDlg')
        },

        addConAnnex(annex) {
            this.content.annexes.splice(this.content.annexes.length, 0, {num: annex.num, name: annex.name, name_E: annex.name_E, refId: annex.id, k: this.genNewK()})
            this.getDocPrjt()
        }
    }
};