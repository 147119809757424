<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: center;">
    <h3 v-if="doc.prjtId">
      文档使用角色设定
      <el-button v-if="amIAuthor || amIPM" type="mini" @click="selRoles">添加</el-button>
    </h3>
    <el-tag style="margin-right: 10px" v-for="(tag, idx) in design.deployRoles" :disable-transitions="false">
      {{tag.name}}<span class="el-icon-close" @click="strikeOut(design.deployRoles, idx)"/>
    </el-tag>

    <selRolesDlg ref="selRolesDlg" :roles="filterRoles" :checks="{}" @oked="addRoles"/>
  </div>
</template>

<script>
import tabMix from "@/doc/tabMix";
import {genMap} from "@/utils/xyhUtils";

export default {
  name: "docRolesPlan",

  mixins:[tabMix],

  components:{},

  data() {
    return {
      filterRoles: [],
    }
  },

  methods: {

    selRoles(){
      let {design, roles} = this
      if(!design.deployRoles){
        design.deployRoles = []
      }
      let deployRolesMap = genMap(design.deployRoles)
      this.filterRoles = roles.filter(role => !deployRolesMap[role.id])
      this.showDlg('selRolesDlg')
    },

    addRoles(roleIds){
      let {design, roles} = this
      let rolesMap = genMap(roles)
      roleIds.forEach(id => this.add(design.deployRoles, design.deployRoles.length,
          rolesMap[id]))
    },

  },

}
</script>

<style scoped>

</style>