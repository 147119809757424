<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
    <div style="padding-top: 10px">
      <h3>术语或缩略语</h3>
      <el-form-item>
        <h4>缩略语表<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.abbrs')"/>
          <el-button v-if="editing" type="primary" size="mini" @click="autoLinkAbbrs">侦测缩略语使用</el-button>
        </h4>
        <docItemRemarks :path="'content.abbrs'"/>
        <table class="table">
          <tr>
            <th>序号</th>
            <th>缩略语<el-button type="mini" @click="sorted('abbrs', 'name')">排序</el-button></th>
            <th>英文全称</th>
            <th>中文描述</th>
          </tr>
          <template v-for="(abbr,idx) in content.abbrs">
            <tr :key="abbr.k">
              <td style="width:50px;"  :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">{{idx+1}}</td>
              <td style="width: 25%" :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">
                <currencyInput :editing="editing" :val.sync="abbr.name" :path="'content.abbrs.' + abbr.k + '.name'" :w="234"
                               :modified="content2 && content2.abbrs[idx] && abbr.name !=content2.abbrs[idx].name"/>
              </td>
              <td :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">
                <currencyInput :editing="editing" :val.sync="abbr.fullEn" :path="'content.abbrs.' + abbr.k + '.fullEn'" :w="327"
                               :modified="content2 && content2.abbrs[idx] && abbr.fullEn !=content2.abbrs[idx].fullEn"/>
              </td>
              <td :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">
                <currencyInput style="padding-top: 10px" :editing="editing" :val.sync="abbr.fullCn" :path="'content.abbrs.' + abbr.k + '.fullCn'"
                               :modified="content2 && content2.abbrs[idx] && abbr.fullCn !=content2.abbrs[idx].fullCn" :w="327"/>
              </td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="add(content.abbrs, idx,Abbr(),content2 ? content2.abbrs:null)" class="el-icon-plus"/>
                <i @click="comment('content.abbrs.' + abbr.k)" class="el-icon-s-comment"/>
                <el-popover v-if="editing" placement="right" width="180" trigger="click">
                  <div style="float: left">
                    <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'abbr',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                    <el-button type="primary" size="mini" v-if="content.abbrs.length > 1" @click="strikeOut(content.abbrs,idx,content2 ? content2.abbrs:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < content.abbrs.length-1" @click="swap(content.abbrs,idx,content2 ? content2.abbrs:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.abbrs,idx-1,content2 ? content2.abbrs:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference">...</el-button>
                </el-popover>
              </td>
            </tr>
            <tr v-if="showComment && remarksMap['content.abbrs.' + abbr.k]" :key="abbr.k + 'c'">
              <td colspan="4" class="rowRemarkBorder">
                <docItemRemarks :path="'content.abbrs.' + abbr.k"/>
              </td>
            </tr>
          </template>
        </table>
        <div v-if="recommendAbbrs.length>0" class="recommendBack">
          <table class="table">
            <tr>
              <th>缩略语</th>
              <th>英文全称</th>
              <th>中文描述</th>
            </tr>
            <tr v-for="(abbr,idx) in recommendAbbrs" class="recommendBack">
              <td style="width: 25%;border: 1px solid">{{ abbr.name }}</td>
              <td style="border: 1px solid">{{ abbr.fullEn }}</td>
              <td style="border: 1px solid">{{ abbr.fullCn }}</td>
              <td class="buttonColumn">
                <el-button v-if="editing"
                           @click="content.abbrs.push({fullEn: abbr.fullEn, name:abbr.name, fullCn: abbr.fullCn, k:genNewK()});recommendAbbrs.splice(idx,1)"
                           style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary"
                           size="mini" class="el-icon-top-right"/>
              </td>
            </tr>
          </table>
        </div>
        <h4>术语表<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.terms')"/>
          <el-button v-if="editing" type="primary" size="mini" @click="autoLinkTerms">侦测术语使用</el-button>
        </h4>
        <docItemRemarks :path="'content.terms'"/>
        <table class="table">
          <tr>
            <th>序号</th>
            <th>术语<el-button type="mini" @click="sorted('terms', 'name')">排序</el-button></th>
            <th>描述</th>
          </tr>
          <template v-for="(term,idx) in content.terms">
            <tr :key="term.k">
              <td style="width:50px;" :class="(content2 && !content2.terms[idx]) ? 'modifyTag':'tdUnmark'">{{idx+1}}</td>
              <td style="width: 40%" :class="(content2 && !content2.terms[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="term.name" :valEn.sync="term.name_E" :path="'content.terms.' + term.k + '.name'" :w="385"
                         :modifiedCn="content2 && content2.terms[idx] && term.name !=content2.terms[idx].name"
                         :modifiedEn="content2 && content2.terms[idx] && term.name_E !=content2.terms[idx].name_E"/>
              </td>
              <td :class="(content2 && !content2.terms[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="term.des" :valEn.sync="term.des_E" :path="'content.terms.' + term.k + '.des'" :w="520"
                         :modifiedCn="content2 && content2.terms[idx] && term.des !=content2.terms[idx].des" :type="'text'"
                         :modifiedEn="content2 && content2.terms[idx] && term.des_E !=content2.terms[idx].des_E"/>
              </td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="add(content.terms, idx,Term(),content2 ? content2.terms:null)" class="el-icon-plus"/>
                <i @click="comment('content.terms.' + term.k)" class="el-icon-s-comment"/>
                <el-popover v-if="editing" placement="right" width="180" trigger="click">
                  <div style="float: left">
                    <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'term',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                    <el-button type="primary" size="mini" v-if="content.terms.length > 1" @click="strikeOut(content.terms,idx,content2 ? content2.terms:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < content.terms.length-1" @click="swap(content.terms,idx,content2 ? content2.terms:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.terms,idx-1,content2 ? content2.terms:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference">...</el-button>
                </el-popover>
              </td>
            </tr>
            <tr v-if="showComment && remarksMap['content.terms.' + term.k]" :key="term.k + 'c'">
              <td colspan="3" class="rowRemarkBorder">
                <docItemRemarks :path="'content.terms.' + term.k"/>
              </td>
            </tr>
          </template>
        </table>
        <div class="recommendBack" v-if="recommendTerms.length>0">
          <table class="table">
            <tr>
              <th>术语</th>
              <th>描述</th>
            </tr>
            <tr v-for="(term,idx) in recommendTerms">
              <td style="width: 40%;border: 1px solid">{{ term.name }}</td>
              <td style="border: 1px solid">{{ term.des }}</td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="content.terms.push({des: term.des, name:term.name, name_E: '', des_E:'', k:genNewK()});recommendTerms.splice(idx,1)"
                           style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary" size="mini" class="el-icon-top-right"/>
              </td>
            </tr>
          </table>
        </div>
      </el-form-item>
    </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "termAndAbbr",

  mixins:[tabMix],

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>