<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <el-form>
      <div style="padding-top: 10px">
        <h4>参考流程或者文件 Related Procedures or Documents<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.refFlows')"/>
          <el-button type="primary" v-if="editing" size="mini" @click="autoLinkRefDocs('doc')">侦测文件引用</el-button>
        </h4>
        <docItemRemarks :path="'content.refFlows'"/>
        <table class="table">
          <tr>
            <th>序号</th>
            <th>文件编号<el-button type="mini" @click="sorted('refFlows', 'num')">排序</el-button></th>
            <th>文件名称</th>
            <th>版本号</th>
          </tr>
          <template v-for="(refFlow,idx) in content.refFlows">
            <tr :key="refFlow.k">
              <td style="width:50px" :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                <a :class="refFlow.nothingness ? 'refNon':'refBe'" v-if="refFlow.refId" @click="previewRefFlow(refFlow.refId)">{{idx+1}}<a class="el-icon-paperclip"/></a>
                <span v-else>{{idx+1}}</span>
              </td>
              <td style="width: 20%" :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                <currencyInput style="padding-top: 10px" :editing="editing" :val.sync="refFlow.num" :path="'content.refFlows.' + refFlow.k + '.num'"
                               :modified="content2 && content2.refFlows[idx] && refFlow.num !=content2.refFlows[idx].num" :w="184"  />
              </td>
              <td :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="refFlow.name" :valEn.sync="refFlow.name_E" :path="'content.refFlows.' + refFlow.k + '.name'" :w="517"
                         :modifiedCn="content2 && content2.refFlows[idx] && refFlow.name !=content2.refFlows[idx].name"
                         :modifiedEn="content2 && content2.refFlows[idx] && refFlow.name_E !=content2.refFlows[idx].name_E"/>
              </td>
              <td style="width: 20%" :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="refFlow.ver" :valEn.sync="refFlow.ver_E" :path="'content.refFlows.' + refFlow.k + '.ver'" :w="184"
                         :modifiedCn="content2 && content2.refFlows[idx] && refFlow.ver !=content2.refFlows[idx].ver"
                         :modifiedEn="content2 && content2.refFlows[idx] && refFlow.ver_E !=content2.refFlows[idx].ver_E"/>
              </td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="add(content.refFlows, idx,RefFlow(),content2 ? content2.refFlows:null)" class="el-icon-plus"/>
                <i @click="comment('content.refFlows.' + refFlow.k)" class="el-icon-s-comment"/>
                <el-popover v-if="editing" placement="right" trigger="click">
                  <div style="float: right">
                    <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;relation=false;showDlg('selRefDocDlg')" class="el-icon-folder-add"/>
                    <el-button type="primary" size="mini" v-if="content.refFlows.length > 1" @click="strikeOut(content.refFlows,idx,content2 ? content2.refFlows:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < content.refFlows.length-1" @click="swap(content.refFlows,idx,content2 ? content2.refFlows:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.refFlows,idx-1,content2 ? content2.refFlows:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference">...</el-button>
                </el-popover>
              </td>
            </tr>
            <tr v-if="showComment && remarksMap['content.refFlows.' + refFlow.k]" :key="refFlow.k + 'c'">
              <td colspan="4" class="rowRemarkBorder">
                <docItemRemarks :path="'content.refFlows.' + refFlow.k"/>
              </td>
            </tr>
          </template>
        </table>
        <div v-if="recommendDocs.length>0" class="recommendBack">
          <table class="table">
            <tr>
              <th>序号</th>
              <th>文件编号</th>
              <th>文件名称</th>
              <th>版本号</th>
            </tr>
            <tr v-for="(refFlow,idx) in recommendDocs">
              <td style="width:50px;border: 1px solid">
                <a style="color: #0D8FBF" @click="previewRefFlow(refFlow.id)">{{ idx + 1 }}<a
                    class="el-icon-paperclip"/></a>
              </td>
              <td style="width: 20%;border: 1px solid">{{ refFlow.num }}</td>
              <td style="border: 1px solid">{{ refFlow.name }}</td>
              <td style="width: 20%;border: 1px solid">{{ refFlow.ver }}</td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="content.refFlows.push({num: refFlow.num, name:refFlow.name, name_E:refFlow.name_E, ver: refFlow.ver, refId:refFlow.id, k:genNewK()});recommendDocs.splice(idx,1)"
                           style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary" size="mini" class="el-icon-top-right"/>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "refFlows",

  mixins:[tabMix],

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>