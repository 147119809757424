<template>
  <div style="width:1024px;padding:0 10px 0 10px;background: #EEEEEE;text-align: left;">
    <nav-top/>
    <div style="text-align:right;padding-top: 20px">
      <p>编号：{{doc.num}}</p>
    </div>
    <div style="text-align:center;padding-top: 20px">
      <h2 v-if="CnOn">{{doc.name}}<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content')"></i></h2>
      <h2 v-if="EnOn">{{doc.name_E}}<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content_E')"></i></h2>
    </div>
    <docItemRemarks :path="'content'"/>
    <div style="clear: both;padding-top: 20px">
      <h3 style="padding-bottom: 10px">参与者</h3>
      <table class="table">
        <tr>
          <th>部门</th>
          <th>职位</th>
          <th>姓名</th>
          <th>签名及日期</th>
        </tr>
        <tr>
          <td colspan="4" class="tdUnmark">
            <h3 style="float: left;padding-left: 20px">作者 Author：</h3>
            <i style="float: left;padding-top: 15px" @click="comment('content.authors')" class="el-icon-s-comment"/>
            <docItemRemarks :path="'content.authors'"/>
          </td>
        </tr>
        <template v-for="(author, idx) in content.authors">
          <tr :key="'author' + author.k">
            <td style="width: 25%" :class="(content2 && !content2.authors[idx]) ? 'modifyTag':'tdUnmark'">
              <docItem :valCn.sync="author.dept" :valEn.sync="author.dept_E" :valId.sync="author.deptId" :path="'content.authors.'+author.k+'.dept'" :w="232"
                          :modifiedCn="content2 && content2.authors[idx] && author.dept != content2.authors[idx].dept" :options="depts"
                          :modifiedEn="content2 && content2.authors[idx] && author.dept_E != content2.authors[idx].dept_E"/>
            </td>
            <td style="width: 25%" :class="(content2 && !content2.authors[idx]) ? 'modifyTag':'tdUnmark'">
              <docItem :valCn.sync="author.position" :valEn.sync="author.position_E" :path="'content.authors.'+author.k+'.position'" :w="232"
                          :modifiedCn="content2 && content2.authors[idx] && author.position != content2.authors[idx].position" :options="roles"
                          :modifiedEn="content2 && content2.authors[idx] && author.position_E != content2.authors[idx].position_E"/>
            </td>
            <td style="width: 25%" :class="(content2 && !content2.authors[idx]) ? 'modifyTag':'tdUnmark'">
              <docItem :valCn.sync="author.name" :valEn.sync="author.name_E" :path="'content.authors.'+author.k+'.name'" :w="232"
                          :modifiedCn="content2 && content2.authors[idx] && author.name != content2.authors[idx].name"
                          :modifiedEn="content2 && content2.authors[idx] && author.name_E != content2.authors[idx].name_E"/>
            </td>
            <td style="width: 25%" :class="(content2 && !content2.authors[idx]) ? 'modifyTag':'tdUnmark'">{{ author.duty }}</td>
            <td class="buttonColumn">
              <el-button v-if="editing" @click="add(content.authors, idx,Person(),content2 ? content2.authors:null)" class="el-icon-plus"/>
              <i @click="comment('content.authors.'+ author.k)" class="el-icon-s-comment"/>
              <el-popover v-if="content.authors.length > 1 && editing" placement="right" width="180" trigger="click">
                <div style="float: left">
                  <el-button type="primary" size="mini" v-if="content.authors.length > 1" @click="strikeOut(content.authors,idx,content2 ? content2.authors:null)" class="el-icon-minus"/>
                  <el-button type="primary" size="mini" v-if="idx < content.authors.length-1" @click="swap(content.authors,idx,content2 ? content2.authors:null)" class="el-icon-bottom"/>
                  <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.authors,idx-1,content2 ? content2.authors:null)" class="el-icon-top"/>
                </div>
                <el-button size="mini" slot="reference">...</el-button>
              </el-popover>
            </td>
          </tr>
          <tr v-if="showComment && remarksMap['content.authors.'+ author.k]" :key="author.k + 'c'">
            <td colspan="4" class="rowRemarkBorder">
              <docItemRemarks :path="'content.authors.'+ author.k"/>
            </td>
          </tr>
        </template>
        <tr>
          <td colspan="4" class="tdUnmark">
            <h3 style="float: left;padding-left: 20px">审核人 Reviewer：</h3>
            <i style="float: left;padding-top: 15px" @click="comment('content.auditors')" class="el-icon-s-comment"/>
            <docItemRemarks :path="'content.auditors'"/>
          </td>
        </tr>
        <template v-for="(auditor, idx) in content.auditors">
          <tr :key="'auditor' + auditor.k">
          <td style="width: 25%" :class="(content2 && !content2.auditors[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="auditor.dept" :valEn.sync="auditor.dept_E" :valId.sync="auditor.deptId" :path="'content.auditors.'+auditor.k+'.dept'" :w="232"
                     :modifiedCn="content2 && content2.auditors[idx] && auditor.dept != content2.auditors[idx].dept" :options="depts"
                     :modifiedEn="content2 && content2.auditors[idx] && auditor.dept_E != content2.auditors[idx].dept_E"/>
          </td>
          <td style="width: 25%" :class="(content2 && !content2.auditors[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="auditor.position" :valEn.sync="auditor.position_E" :path="'content.auditors.'+auditor.k+'.position'" :w="232"
                     :modifiedCn="content2 && content2.auditors[idx] && auditor.position != content2.auditors[idx].position" :options="roles"
                     :modifiedEn="content2 && content2.auditors[idx] && auditor.position_E != content2.auditors[idx].position_E"/>
          </td>
          <td style="width: 25%" :class="(content2 && !content2.auditors[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="auditor.name" :valEn.sync="auditor.name_E" :path="'content.auditors.'+auditor.k+'.name'" :w="232"
                     :modifiedCn="content2 && content2.auditors[idx] && auditor.name != content2.auditors[idx].name"
                     :modifiedEn="content2 && content2.auditors[idx] && auditor.name_E != content2.auditors[idx].name_E"/>
          </td>
          <td style="width: 25%" :class="(content2 && !content2.auditors[idx]) ? 'modifyTag':'tdUnmark'">{{ auditor.duty }}</td>
          <td class="buttonColumn">
            <el-button v-if="editing" @click="add(content.auditors, idx,Person(),content2 ? content2.auditors:null)" class="el-icon-plus"/>
            <i @click="comment('content.auditors.'+ auditor.k)" class="el-icon-s-comment"/>
            <el-popover v-if="content.auditors.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.auditors.length > 1" @click="strikeOut(content.auditors,idx,content2 ? content2.auditors:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="idx < content.auditors.length-1" @click="swap(content.auditors,idx,content2 ? content2.auditors:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.auditors,idx-1,content2 ? content2.auditors:null)" class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference">...</el-button>
            </el-popover>
          </td>
        </tr>
          <tr v-if="showComment && remarksMap['content.auditors.'+ auditor.k]" :key="auditor.k + 'c'">
            <td colspan="4" class="rowRemarkBorder">
              <docItemRemarks :path="'content.auditors.'+ auditor.k"/>
            </td>
          </tr>
        </template>
        <tr>
          <td colspan="4" class="tdUnmark">
            <h3 style="float: left;padding-left: 20px">批准人 Approver：</h3>
            <i style="float: left;padding-top: 15px" @click="comment('content.authorizers')" class="el-icon-s-comment"/>
            <docItemRemarks :path="'content.authorizers'"/>
          </td>
        </tr>
        <template v-for="(authorizer, idx) in content.authorizers">
        <tr :key="'authorizer' + authorizer.k">
          <td style="width: 25%" :class="(content2 && !content2.authorizers[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="authorizer.dept" :valEn.sync="authorizer.dept_E" :valId.sync="authorizer.deptId" :path="'content.authorizers.'+authorizer.k+'.dept'" :w="232"
                     :modifiedCn="content2 && content2.authorizers[idx] && authorizer.dept != content2.authorizers[idx].dept" :options="depts"
                     :modifiedEn="content2 && content2.authorizers[idx] && authorizer.dept_E != content2.authorizers[idx].dept_E"/>
          </td>
          <td style="width: 25%" :class="(content2 && !content2.authorizers[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="authorizer.position" :valEn.sync="authorizer.position_E" :path="'content.authorizers.'+authorizer.k+'.position'" :w="232"
                     :modifiedCn="content2 && content2.authorizers[idx] && authorizer.position != content2.authorizers[idx].position" :options="roles"
                     :modifiedEn="content2 && content2.authorizers[idx] && authorizer.position_E != content2.authorizers[idx].position_E"/>
          </td>
          <td style="width: 25%" :class="(content2 && !content2.authorizers[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="authorizer.name" :valEn.sync="authorizer.name_E" :path="'content.authorizers.'+authorizer.k+'.name'" :w="232"
                     :modifiedCn="content2 && content2.authorizers[idx] && authorizer.name != content2.authorizers[idx].name"
                     :modifiedEn="content2 && content2.authorizers[idx] && authorizer.name_E != content2.authorizers[idx].name_E"/>
          </td>
          <td style="width: 25%" :class="(content2 && !content2.authorizers[idx]) ? 'modifyTag':'tdUnmark'">{{ authorizer.duty }}</td>
          <td class="buttonColumn">
            <el-button v-if="editing" @click="add(content.authorizers, idx,Person(),content2 ? content2.authorizers:null)" class="el-icon-plus"/>
            <i @click="comment('content.authorizers.'+ authorizer.k)" class="el-icon-s-comment"/>
            <el-popover v-if="content.authorizers.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.authorizers.length > 1"
                           @click="strikeOut(content.authorizers,idx,content2 ? content2.authorizers:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="idx < content.authorizers.length-1"
                           @click="swap(content.authorizers,idx,content2 ? content2.authorizers:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.authorizers,idx-1,content2 ? content2.authorizers:null)"
                           class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference">...</el-button>
            </el-popover>
          </td>
        </tr>
        <tr v-if="showComment && remarksMap['content.authorizers.'+ authorizer.k]" :key="authorizer.k + 'c'">
          <td colspan="4" class="rowRemarkBorder">
            <docItemRemarks :path="'content.authorizers.'+ authorizer.k"/>
          </td>
        </tr>
        </template>
      </table>
      <table class="table" style="margin-top: 30px;width: 987px">
        <tr>
          <td width="10%" class="tdUnmark">发布日期</td>
          <td class="tdUnmark">
            <p style="float:left;width: 870px" v-if="!editing" :class="(content2 && content.issueDate != content2.issueDate) ? 'modifyTag':'pUnmark'">{{content.issueDate}}</p>
            <el-date-picker style="float:left;width: 870px" v-else v-model="content.issueDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"/>
            <i @click="comment('content.issueDate')" class="el-icon-s-comment"/>
            <docItemRemarks :path="'content.issueDate'"/>
          </td>
        </tr>
        <tr>
          <td class="tdUnmark">生效日期</td>
          <td class="tdUnmark">
            <p style="float:left;width: 870px" v-if="!editing" :class="(content2 && content.sDate != content2.sDate) ? 'modifyTag':'pUnmark'">{{content.sDate}}</p>
            <el-date-picker style="float:left;width: 870px" v-else v-model="content.sDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"/>
            <i @click="comment('content.sDate')" class="el-icon-s-comment"/>
            <docItemRemarks :path="'content.sDate'"/>
          </td>
        </tr>
        <tr>
          <td class="tdUnmark">编订部门</td>
          <td class="tdUnmark">
            <docItem :valCn.sync="content.deptEdt" :valEn.sync="content.deptEdt_E" :valId.sync="content.deptEdtId" :path="'content.deptEdt'" :w="870"
                     :modifiedCn="content2 && content.deptEdt != content2.deptEdt" :options="depts"
                     :modifiedEn="content2 && content.deptEdt_E != content2.deptEdt_E"/>
          </td>
        </tr>
        <tr>
          <td class="tdUnmark">发布范围</td>
          <td class="tdUnmark">
            <docItem :valCn.sync="content.deptsRecv" :valEn.sync="content.deptsRecv_E" :path="'content.deptsRecv'" :w="870"
                     :modifiedCn="content2 && content.deptsRecv != content2.deptsRecv" :relation="'dept'" :type="'text'"
                     :modifiedEn="content2 && content.deptsRecv_E != content2.deptsRecv_E"/>
          </td>
        </tr>
      </table>
    </div>
    <div style="width:90%;float: left;clear: both;padding-top: 20px">
      <h3>第一部分：目的</h3>
      <docItem :valCn.sync="content.purpose" :valEn.sync="content.purpose_E" :path="'content.purpose'" :w="885"
               :modifiedCn="content2 && content.purpose != content2.purpose" :type="'text'" :relation="'doc'"
               :modifiedEn="content2 && content.purpose_E != content2.purpose_E"/>
    </div>
    <div style="width:100%;float: left;clear: both;padding-top: 20px">
      <h3>第二部分：范围<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.scopes')"/></h3>
      <docItemRemarks :path="'content.scopes'"/>
      <table class="table" style="margin-top: 10px">
        <tr>
          <th>范围名称</th>
          <th>范围描述</th>
        </tr>
        <template v-for="(scope,idx) in content.scopes">
        <tr :key="scope.k">
          <td style="width: 25%" :class="(content2 && !content2.scopes[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="scope.name" :valEn.sync="scope.name_E" :path="'content.scopes.' + scope.k + '.name'" :w="235"
                     :modifiedCn="content2 && content2.scopes[idx] && scope.name != content2.scopes[idx].name"
                     :modifiedEn="content2 && content2.scopes[idx] && scope.name_E != content2.scopes[idx].name_E"/>
          </td>
          <td :class="(content2 && !content2.scopes[idx]) ? 'modifyTag':'tdUnmark'">
            <docItem :valCn.sync="scope.des" :valEn.sync="scope.des_E" :path="'content.scopes.' + scope.k + '.des'" :w="720" :type="'text'"
                     :modifiedCn="content2 && content2.scopes[idx] && scope.des != content2.scopes[idx].des" :relation="'doc'"
                     :modifiedEn="content2 && content2.scopes[idx] && scope.des_E != content2.scopes[idx].des_E"/>
          </td>
          <td class="buttonColumn">
            <el-button v-if="editing" @click="add(content.scopes, idx,Scope(),content2 ? content2.scopes:null)" class="el-icon-plus"/>
            <i @click="comment('content.scopes.' + scope.k)" class="el-icon-s-comment"/>
            <el-popover v-if="content.scopes.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.scopes.length > 1" @click="strikeOut(content.scopes,idx,content2 ? content2.scopes:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="idx < content.scopes.length-1" @click="swap(content.scopes,idx,content2 ? content2.scopes:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.scopes,idx-1,content2 ? content2.scopes:null)" class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference">...</el-button>
            </el-popover>
          </td>
        </tr>
          <tr v-if="showComment && remarksMap['content.scopes.'+ scope.k]" :key="scope.k + 'c'">
            <td colspan="2" class="rowRemarkBorder">
              <docItemRemarks :path="'content.scopes.'+ scope.k"/>
            </td>
          </tr>
        </template>
      </table>
      <h4 style="padding-top: 10px">范围额外说明</h4>
      <docItem :valCn.sync="content.scopeNote" :valEn.sync="content.scopeNote_E" :path="'content.scopeNote'" :w="985"
               :modifiedCn="content2 && content.scopeNote != content2.scopeNote" :type="'text'" :relation="'doc'"
               :modifiedEn="content2 && content.scopeNote_E != content2.scopeNote_E"/>
    </div>
    <el-form style="clear: both;padding-top: 20px">
      <div style="padding-top: 10px">
        <h3>第三部分：术语或缩略语</h3>
        <el-form-item>
          <h4>缩略语表<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.abbrs')"/>
            <el-button v-if="editing" type="primary" size="mini" @click="autoLinkAbbrs">侦测缩略语使用</el-button>
          </h4>
          <docItemRemarks :path="'content.abbrs'"/>
          <table class="table">
            <tr>
              <th>序号</th>
              <th>缩略语<el-button type="mini" @click="sorted('abbrs', 'name')">排序</el-button></th>
              <th>英文全称</th>
              <th>中文描述</th>
            </tr>
            <template v-for="(abbr,idx) in content.abbrs">
              <tr :key="abbr.k">
                <td style="width:50px;"  :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">{{idx+1}}</td>
                <td style="width: 25%" :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput :editing="editing" :val.sync="abbr.name" :path="'content.abbrs.' + abbr.k + '.name'"
                                 :w="234"
                                 :modified="content2 && content2.abbrs[idx] && abbr.name !=content2.abbrs[idx].name"/>
                </td>
                <td :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput :editing="editing" :val.sync="abbr.fullEn" :path="'content.abbrs.' + abbr.k + '.fullEn'" :w="327"
                                 :modified="content2 && content2.abbrs[idx] && abbr.fullEn !=content2.abbrs[idx].fullEn"/>
                </td>
                <td :class="(content2 && !content2.abbrs[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput :editing="editing" :val.sync="abbr.fullCn" :path="'content.abbrs.' + abbr.k + '.fullCn'"
                                 :modified="content2 && content2.abbrs[idx] && abbr.fullCn !=content2.abbrs[idx].fullCn" :w="327"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.abbrs, idx,Abbr(),content2 ? content2.abbrs:null)" class="el-icon-plus"/>
                  <i @click="comment('content.abbrs.' + abbr.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'abbr',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                      <el-button type="primary" size="mini" v-if="content.abbrs.length > 1" @click="strikeOut(content.abbrs,idx,content2 ? content2.abbrs:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.abbrs.length-1" @click="swap(content.abbrs,idx,content2 ? content2.abbrs:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.abbrs,idx-1,content2 ? content2.abbrs:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.abbrs.' + abbr.k]" :key="abbr.k + 'c'">
                <td colspan="4" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.abbrs.' + abbr.k"/>
                </td>
              </tr>
            </template>
          </table>
          <div v-if="recommendAbbrs.length>0" class="recommendBack">
            <table class="table">
              <tr>
                <th>缩略语</th>
                <th>英文全称</th>
                <th>中文描述</th>
              </tr>
              <tr v-for="(abbr,idx) in recommendAbbrs" class="recommendBack">
                <td style="width: 25%;border: 1px solid">{{ abbr.name }}</td>
                <td style="border: 1px solid">{{ abbr.fullEn }}</td>
                <td style="border: 1px solid">{{ abbr.fullCn }}</td>
                <td class="buttonColumn">
                  <el-button v-if="editing"
                             @click="content.abbrs.push({fullEn: abbr.fullEn, name:abbr.name, fullCn: abbr.fullCn, k:genNewK()});recommendAbbrs.splice(idx,1)"
                             style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary"
                             size="mini" class="el-icon-top-right"/>
                </td>
              </tr>
            </table>
          </div>
          <h4>术语表<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.terms')"/>
            <el-button v-if="editing" type="primary" size="mini" @click="autoLinkTerms">侦测术语使用</el-button>
          </h4>
          <docItemRemarks :path="'content.terms'"/>
          <table class="table">
            <tr>
              <th>序号</th>
              <th>术语<el-button type="mini" @click="sorted('terms', 'name')">排序</el-button></th>
              <th>描述</th>
            </tr>
            <template v-for="(term,idx) in content.terms">
              <tr :key="term.k">
                <td style="width:50px;" :class="(content2 && !content2.terms[idx]) ? 'modifyTag':'tdUnmark'">{{idx+1}}</td>
                <td style="width: 40%" :class="(content2 && !content2.terms[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="term.name" :valEn.sync="term.name_E" :path="'content.terms.' + term.k + '.name'" :w="385"
                           :modifiedCn="content2 && content2.terms[idx] && term.name !=content2.terms[idx].name"
                           :modifiedEn="content2 && content2.terms[idx] && term.name_E !=content2.terms[idx].name_E"/>
                </td>
                <td :class="(content2 && !content2.terms[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="term.des" :valEn.sync="term.des_E" :path="'content.terms.' + term.k + '.des'" :w="520"
                           :modifiedCn="content2 && content2.terms[idx] && term.des !=content2.terms[idx].des" :type="'text'"
                           :modifiedEn="content2 && content2.terms[idx] && term.des_E !=content2.terms[idx].des_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.terms, idx,Term(),content2 ? content2.terms:null)" class="el-icon-plus"/>
                  <i @click="comment('content.terms.' + term.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'term',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                      <el-button type="primary" size="mini" v-if="content.terms.length > 1" @click="strikeOut(content.terms,idx,content2 ? content2.terms:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.terms.length-1" @click="swap(content.terms,idx,content2 ? content2.terms:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.terms,idx-1,content2 ? content2.terms:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.terms.' + term.k]" :key="term.k + 'c'">
                <td colspan="3" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.terms.' + term.k"/>
                </td>
              </tr>
            </template>
          </table>
          <div v-if="recommendTerms.length>0" class="recommendBack">
            <table class="table">
              <tr>
                <th>术语</th>
                <th>描述</th>
              </tr>
              <tr v-for="(term,idx) in recommendTerms">
                <td style="width: 40%;border: 1px solid">{{ term.name }}</td>
                <td style="border: 1px solid">{{ term.des }}</td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="content.terms.push({des: term.des, name:term.name, name_E: '', des_E:'', k:genNewK()});recommendTerms.splice(idx,1)"
                             style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary" size="mini" class="el-icon-top-right"/>
                </td>
              </tr>
            </table>
          </div>
        </el-form-item>
      </div>
      <div>
        <h3>第四部分:原则和规范<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.principleGroups')"/></h3>
        <docItemRemarks :path="'content.principleGroups'"/>
        <el-form-item v-for="(principleGroup, index) in content.principleGroups" :key="principleGroup.k">
          <div style="margin-top: 10px" :class="(content2 && !content2.principleGroups[index]) ? 'modifyTag':'divUnmark'">
            <h3 style="margin:0 20px;line-height:48px;float: left">名称:</h3>
            <docItem :valCn.sync="principleGroup.name" :valEn.sync="principleGroup.name_E" :path="'content.principleGroups.' + principleGroup.k + '.name'" :w="500"
                     :modifiedCn="content2 && content2.principleGroups[index] && principleGroup.name != content2.principleGroups[index].name" :type="'text'"
                     :modifiedEn="content2 && content2.principleGroups[index] && principleGroup.name_E != content2.principleGroups[index].name_E"/>
            <table class="table">
              <tr>
                <th>规范内容</th>
                <th>规范列表</th>
              </tr>
              <template v-for="(principle,idx) in principleGroup.principles">
                <tr :key="principle.k">
                  <td style="width: 30%"  :class="(content2 && content2.principleGroups[index] && !content2.principleGroups[index].principles[idx]) ? 'modifyTag':'tdUnmark'">
                    <docItem :valCn.sync="principle.name" :valEn.sync="principle.name_E" :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.name'" :w="283"
                             :modifiedCn="content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx] && principle.name != content2.principleGroups[index].principles[idx].name"
                             :modifiedEn="content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx] && principle.name_E != content2.principleGroups[index].principles[idx].name_E"/>
                  </td>
                  <td style="width: 69%"  :class="(content2 && content2.principleGroups[index] && !content2.principleGroups[index].principles[idx]) ? 'modifyTag':'tdUnmark'">
                    <div v-for="(des,idex) in principle.des" :key="des.k">
                      <div v-if="CnOn">
                        <i v-if="!CnEditing" @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v')" class="el-icon-s-comment"/>
                        <pre v-if="!CnEditing && des.v" v-html="displayContent['content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v']"
                             :class="(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx] && content2.principleGroups[index].principles[idx].des[idex] && des.v != content2.principleGroups[index].principles[idx].des[idex].v) ? 'modifyTag':'pUnmark'">● {{des.v}}</pre>
                        <p v-if="CnEditing" style="width: 10px;float: left">● </p>
                        <el-input v-if="CnEditing" style="width: 647px" @input="updateDisplayContent('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v', des.v)"
                                  :id="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v'" v-model="principle.des[idex].v" type="textarea" rows="2" :autosize="{ minRows: 2}"/>
                        <div v-if="CnEditing" style="width: 15px;float: right">
                          <el-button @click="add(principle.des, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-plus"/>
                          <i @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v')" class="el-icon-s-comment"/>
                          <el-popover placement="right" width="180" trigger="click">
                            <div style="float: left">
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v','doc')">ref</el-button>
                              <el-button type="primary" size="mini" v-if="principle.des.length > 1" @click="strikeOut(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-minus"/>
                              <el-button type="primary" size="mini" v-if="idex < principle.des.length-1" @click="swap(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-bottom"/>
                              <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(principle.des,idex-1,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-top"/>
                            </div>
                            <el-button size="mini" slot="reference">...</el-button>
                          </el-popover>
                        </div>
                        <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v'"/>
                      </div>
                      <div v-if="EnOn" style="background:#FDE2E7">
                        <i v-if="!EnEditing" @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                        <pre v-if="!EnEditing && des.v_E" v-html="displayContent['content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E'] || des.v_E">● {{des.v_E}}</pre>
                        <p v-if="EnEditing" style="width: 10px;float: left">● </p>
                        <el-input v-if="EnEditing" style="width: 647px" @input="updateDisplayContent('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E', des.v_E)"
                                  :id="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E'"  v-model="principle.des[idex].v_E" type="textarea" :autosize="{ minRows: 2}"/>
                        <div v-if="EnEditing" style="width: 15px;float: right">
                          <el-button @click="add(principle.des, idex,{v:'',v_E:'', k:genNewK()},(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-plus"/>
                          <i @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E')" class="el-icon-s-comment"/>
                          <el-popover placement="right" width="180" trigger="click">
                            <div style="float: left">
                              <el-button type="primary" size="mini" @click="selRelationDoc('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E','doc')">ref</el-button>
                              <el-button type="primary" size="mini" v-if="principle.des.length > 1" @click="strikeOut(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-minus"/>
                              <el-button type="primary" size="mini" v-if="idex < principle.des.length-1" @click="swap(principle.des,idex,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-bottom"/>
                              <el-button type="primary" size="mini" v-if="idex > 0" @click="swap(principle.des,idex-1,(content2 && content2.principleGroups[index] && content2.principleGroups[index].principles[idx]) ? content2.principleGroups[index].principles[idx].des:null)" class="el-icon-top"/>
                            </div>
                            <el-button size="mini" slot="reference">...</el-button>
                          </el-popover>
                        </div>
                        <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k + '.v_E'"/>
                      </div>
                      <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k + '.des.' + des.k"/>
                    </div>
                  </td>
                  <td class="buttonColumn">
                    <el-button v-if="editing" @click="add(principleGroup.principles, idx,{name:'',name_E:'',des:[{v:'', k:genNewK()}], k:genNewK()},(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-plus"/>
                    <i @click="comment('content.principleGroups.' + principleGroup.k + '.principles.' + principle.k)" class="el-icon-s-comment"/>
                    <el-popover v-if="principleGroup.principles.length > 1 && editing" placement="right" width="180" trigger="click">
                      <div style="float: left">
                        <el-button type="primary" size="mini" v-if="principleGroup.principles.length > 1" @click="strikeOut(principleGroup.principles,idx,(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-minus"/>
                        <el-button type="primary" size="mini" v-if="idx < principleGroup.principles.length-1" @click="swap(principleGroup.principles,idx,(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-bottom"/>
                        <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(principleGroup.principles,idx-1,(content2 && content2.principleGroups[index]) ? content2.principleGroups[index].principles:null)" class="el-icon-top"/>
                      </div>
                      <el-button size="mini" slot="reference">...</el-button>
                    </el-popover>
                  </td>
                </tr>
                <tr v-if="showComment && remarksMap['content.principleGroups.' + principleGroup.k + '.principles.' + principle.k]" :key="principle.k + 'c'">
                  <td colspan="2" class="rowRemarkBorder">
                    <docItemRemarks :path="'content.principleGroups.' + principleGroup.k + '.principles.' + principle.k"/>
                  </td>
                </tr>
              </template>
            </table>
            <div v-if="editing" style="height: 10px"></div>
            <el-button v-if="editing" type="mini" style="float: right;margin: 0 15px 0 10px;padding: 0 5px 0 5px" @click="add(content.principleGroups, index, {name: '',name_E:'', principles: [{name: '', name_E:'',des: [{v:'', v_E:'',k:genNewK()}], k:genNewK()}], k:genNewK()},content2 ? content2.principleGroups:null)" class="el-icon-plus"/>
            <i style="float: right;margin: 0 15px 0 10px;color: #0D8FBF;padding: 0 5px 0 5px" @click="comment('content.principleGroups.' + principleGroup.k)" class="el-icon-s-comment"/>
            <el-popover v-if="content.principleGroups.length > 1 && editing" placement="right" width="180" trigger="click">
              <div style="float: left">
                <el-button type="primary" size="mini" v-if="content.principleGroups.length > 1" @click="strikeOut(content.principleGroups,index,content2 ? content2.principleGroups:null)" class="el-icon-minus"/>
                <el-button type="primary" size="mini" v-if="index < content.principleGroups.length-1" @click="swap(content.principleGroups,index,content2 ? content2.principleGroups:null)" class="el-icon-bottom"/>
                <el-button type="primary" size="mini" v-if="index > 0" @click="swap(content.principleGroups,index-1,content2 ? content2.principleGroups:null)" class="el-icon-top"/>
              </div>
              <el-button size="mini" slot="reference" style="float: right;margin-left: 10px;padding: 0 5px 0 5px">...</el-button>
            </el-popover>
          </div>
          <docItemRemarks :path="'content.principleGroups.' + principleGroup.k"/>
        </el-form-item>
      </div>
      <div>
        <h3 style="padding-bottom: 10px">第五部分：政策执行<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.excutions')"/></h3>
        <docItemRemarks :path="'content.excutions'"/>
        <el-form-item>
          <table class="table">
            <tr>
              <th>名称</th>
              <th>描述</th>
            </tr>
            <template v-for="(excution,idx) in content.excutions">
            <tr :key="excution.k">
              <td style="width: 30%" :class="(content2 && !content2.excutions[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="excution.name" :valEn.sync="excution.name_E" :path="'content.excutions.' + excution.k + '.name'" :w="285"
                         :modifiedCn="content2 && content2.excutions[idx] && excution.name !=content2.excutions[idx].name"
                         :modifiedEn="content2 && content2.excutions[idx] && excution.name_E !=content2.excutions[idx].name_E"/>
              </td>
              <td :class="(content2 && !content2.excutions[idx]) ? 'modifyTag':'tdUnmark'">
                <docItem :valCn.sync="excution.des" :valEn.sync="excution.des_E" :path="'content.excutions.' + excution.k + '.des'" :w="666" :type="'text'"
                         :modifiedCn="content2 && content2.excutions[idx] && excution.des !=content2.excutions[idx].des" :relation="'doc'"
                         :modifiedEn="content2 && content2.excutions[idx] && excution.des_E !=content2.excutions[idx].des_E"/>
              </td>
              <td class="buttonColumn">
                <el-button v-if="editing" @click="add(content.excutions, idx,Excution(),content2 ? content2.excutions:null)" class="el-icon-plus"/>
                <i @click="comment('content.excutions.' + idx)" class="el-icon-s-comment"/>
                <el-popover v-if="content.excutions.length > 1 && editing" placement="right" width="180" trigger="click">
                  <div style="float: left">
                    <el-button type="primary" size="mini" v-if="content.excutions.length > 1" @click="strikeOut(content.excutions,idx,content2 ? content2.excutions:null)" class="el-icon-minus"/>
                    <el-button type="primary" size="mini" v-if="idx < content.excutions.length-1" @click="swap(content.excutions,idx,content2 ? content2.excutions:null)" class="el-icon-bottom"/>
                    <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.excutions,idx-1,content2 ? content2.excutions:null)" class="el-icon-top"/>
                  </div>
                  <el-button size="mini" slot="reference">...</el-button>
                </el-popover>
              </td>
            </tr>
              <tr v-if="showComment && remarksMap['content.excutions.' + idx]" :key="excution.k + 'c'">
                <td colspan="2" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.excutions.' + idx"/>
                </td>
              </tr>
            </template>
          </table>
        </el-form-item>
      </div>
      <div>
        <h3>第六部分：参考法规和文件</h3>
        <el-form-item>
          <h4>参考法规 Related Laws or Regulations<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.refLaws')"/></h4>
          <docItemRemarks :path="'content.refLaws'"/>
          <table class="table">
            <tr>
              <th>序号</th>
              <th>法规名称<el-button type="mini" @click="sorted('refLaws', 'name')">排序</el-button></th>
              <th>发布机构</th>
              <th>发布号</th>
              <th>发布年月</th>
            </tr>
            <template v-for="(refLaw,idx) in content.refLaws">
              <tr :key="refLaw.k">
                <td style="width:50px;" :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                  <a style="color: #0D8FBF" v-if="refLaw.linkUrl" :href="refLaw.linkUrl" target="_blank">{{idx+1}}<a class="el-icon-paperclip"/></a>
                  <span v-else>{{idx+1}}</span>
                </td>
                <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="refLaw.name" :valEn.sync="refLaw.name_E" :path="'content.refLaws.' + refLaw.k + '.name'" :w="218"
                           :modifiedCn="content2 && content2.refLaws[idx] && refLaw.name !=content2.refLaws[idx].name"
                           :modifiedEn="content2 && content2.refLaws[idx] && refLaw.name_E !=content2.refLaws[idx].name_E"/>
                </td>
                <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="refLaw.issuer" :valEn.sync="refLaw.issuer_E" :path="'content.refLaws.' + refLaw.k + '.issuer'" :w="218"
                           :modifiedCn="content2 && content2.refLaws[idx] && refLaw.issuer !=content2.refLaws[idx].issuer"
                           :modifiedEn="content2 && content2.refLaws[idx] && refLaw.issuer_E !=content2.refLaws[idx].issuer_E"/>
                </td>
                <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="refLaw.num" :valEn.sync="refLaw.num_E" :path="'content.refLaws.' + refLaw.k + '.num'" :w="218"
                           :modifiedCn="content2 && content2.refLaws[idx] && refLaw.num !=content2.refLaws[idx].num"
                           :modifiedEn="content2 && content2.refLaws[idx] && refLaw.num_E !=content2.refLaws[idx].num_E"/>
                </td>
                <td :class="(content2 && !content2.refLaws[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="refLaw.date" :valEn.sync="refLaw.date_E" :path="'content.refLaws.' + refLaw.k + '.date'" :w="218"
                           :modifiedCn="content2 && content2.refLaws[idx] && refLaw.date !=content2.refLaws[idx].date"
                           :modifiedEn="content2 && content2.refLaws[idx] && refLaw.date_E !=content2.refLaws[idx].date_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.refLaws, idx,RefLaw(),content2 ? content2.refLaws:null)" class="el-icon-plus"/>
                  <i @click="comment('content.refLaws.' + idx)" class="el-icon-s-comment"/>
                  <el-popover v-if="editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'law',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                      <el-button type="primary" size="mini" v-if="content.refLaws.length > 1" @click="strikeOut(content.refLaws,idx,content2 ? content2.refLaws:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.refLaws.length-1" @click="swap(content.refLaws,idx,content2 ? content2.refLaws:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.refLaws,idx-1,content2 ? content2.refLaws:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.refLaws.' + idx]" :key="refLaw.k + 'c'">
                <td colspan="5" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.refLaws.' + idx"/>
                </td>
              </tr>
            </template>
          </table>
          <h4>参考文件 Related Procedures or Documents<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.refFlows')"/>
            <el-button v-if="editing" type="primary" size="mini" @click="autoLinkRefDocs('doc')">侦测文件引用</el-button>
          </h4>
          <docItemRemarks :path="'content.refFlows'"/>
          <table class="table">
            <tr>
              <th>序号</th>
              <th>文件编号<el-button type="mini" @click="sorted('refFlows', 'num')">排序</el-button></th>
              <th>文件名称</th>
              <th>版本号</th>
            </tr>
            <template v-for="(refFlow,idx) in content.refFlows">
              <tr>
                <td style="width:50px;border: 1px solid" :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                  <a :class="refFlow.nothingness ? 'refNon':'refBe'" v-if="refFlow.refId" @click="previewRefFlow(refFlow.refId)">{{idx+1}}<a class="el-icon-paperclip"/></a>
                  <span v-else>{{idx+1}}</span>
                </td>
                <td style="width: 20%" :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput style="padding-top: 10px" :editing="editing" :val.sync="refFlow.num" :path="'content.refFlows.' + refFlow.k + '.num'"
                                 :modified="content2 && content2.refFlows[idx] && refFlow.num !=content2.refFlows[idx].num" :w="184"/>
                </td>
                <td :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="refFlow.name" :valEn.sync="refFlow.name_E" :path="'content.refFlows.' + refFlow.k + '.name'" :w="517"
                           :modifiedCn="content2 && content2.refFlows[idx] && refFlow.name !=content2.refFlows[idx].name"
                           :modifiedEn="content2 && content2.refFlows[idx] && refFlow.name_E !=content2.refFlows[idx].name_E"/>
                </td>
                <td style="width: 20%" :class="(content2 && !content2.refFlows[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="refFlow.ver" :valEn.sync="refFlow.ver_E" :path="'content.refFlows.' + refFlow.k + '.ver'" :w="184"
                           :modifiedCn="content2 && content2.refFlows[idx] && refFlow.ver !=content2.refFlows[idx].ver"
                           :modifiedEn="content2 && content2.refFlows[idx] && refFlow.ver_E !=content2.refFlows[idx].ver_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.refFlows, idx,RefFlow(),content2 ? content2.refFlows:null)" class="el-icon-plus"/>
                  <i @click="comment('content.refFlows.' + refFlow.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="editing" placement="right" trigger="click">
                    <div style="float: right">
                      <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;showDlg('selRefDocDlg')" class="el-icon-folder-add"/>
                      <el-button type="primary" size="mini" v-if="content.refFlows.length > 1" @click="strikeOut(content.refFlows,idx,content2 ? content2.refFlows:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.refFlows.length-1" @click="swap(content.refFlows,idx,content2 ? content2.refFlows:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.refFlows,idx-1,content2 ? content2.refFlows:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.refFlows.' + refFlow.k]" :key="refFlow.k + 'c'">
                <td colspan="4" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.refFlows.' + refFlow.k"/>
                </td>
              </tr>
            </template>
          </table>
          <div v-if="recommendDocs.length>0" class="recommendBack">
            <table class="table">
              <tr>
                <th>序号</th>
                <th>文件编号</th>
                <th>文件名称</th>
                <th>版本号</th>
              </tr>
              <tr v-for="(refFlow,idx) in recommendDocs" >
                <td style="width:50px;border: 1px solid">
                  <a style="color: #0D8FBF" @click="previewRefFlow(refFlow.id)">{{ idx + 1 }}<a
                      class="el-icon-paperclip"/></a>
                </td>
                <td style="width: 20%;border: 1px solid">{{ refFlow.num }}</td>
                <td style="border: 1px solid">{{ refFlow.name }}</td>
                <td style="width: 20%;border: 1px solid">{{ refFlow.ver }}</td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="content.refFlows.push({num: refFlow.num, name:refFlow.name, name_E:refFlow.name_E, ver: refFlow.ver, refId:refFlow.id, k:genNewK()});recommendDocs.splice(idx,1)"
                             style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary" size="mini" class="el-icon-top-right"/>
                </td>
              </tr>
            </table>
          </div>
        </el-form-item>
        <h3 style="padding-bottom: 10px">附件<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.annexes')"/>
          <el-button v-if="editing" type="primary" size="mini" @click="autoLinkRefDocs('annex')">侦测附件引用</el-button>
        </h3>
        <docItemRemarks :path="'content.annexes'"/>
        <el-form-item>
          <table class="table">
            <tr>
              <th>序号</th>
              <th>附件编号<el-button type="mini" @click="sorted('annexes', 'num')">排序</el-button></th>
              <th>附件名称</th>
            </tr>
            <template v-for="(annexe,idx) in content.annexes">
              <tr :key="annexe.k">
                <td style="width: 5%" :class="(content2 && !content2.annexes[idx]) ? 'modifyTag':'tdUnmark'">
                  <a :class="annexe.nothingness ? 'refNon':'refBe'" v-if="annexe.refId" @click="previewAnnexe(annexe.refId)">{{idx+1}}<a class="el-icon-paperclip"/></a>
                  <span v-else>{{idx+1}}</span>
                </td>
                <td style="width: 30%" :class="(content2 && !content2.annexes[idx]) ? 'modifyTag':'tdUnmark'">
                  <currencyInput style="padding-top: 10px" :editing="editing" :val.sync="annexe.num" :path="'content.annexes.' + annexe.k + '.num'"
                                 :modified="content2 && content2.annexes[idx] && annexe.num !=content2.annexes[idx].num" :w="285"/>
                </td>
                <td :class="(content2 && !content2.annexes[idx]) ? 'modifyTag':'tdUnmark'">
                  <docItem :valCn.sync="annexe.name" :valEn.sync="annexe.name_E" :path="'content.annexes.' + annexe.k + '.name'" :w="620"
                           :modifiedCn="content2 && content2.annexes[idx] && annexe.name !=content2.annexes[idx].name"
                           :modifiedEn="content2 && content2.annexes[idx] && annexe.name_E !=content2.annexes[idx].name_E"/>
                </td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="add(content.annexes, idx,Annex(),content2 ? content2.annexes:null)" class="el-icon-plus"/>
                  <i @click="comment('content.annexes.' + annexe.k)" class="el-icon-s-comment"/>
                  <el-popover v-if="editing" placement="right" width="180" trigger="click">
                    <div style="float: left">
                      <el-button type="primary" size="mini" @click="refIdxToSet=idx + 1;refPropN = 'annex',showDlg('selRefDlg')" class="el-icon-folder-add"/>
                      <el-button type="primary" size="mini" v-if="content.annexes.length > 1" @click="strikeOut(content.annexes,idx,content2 ? content2.annexes:null)" class="el-icon-minus"/>
                      <el-button type="primary" size="mini" v-if="idx < content.annexes.length-1" @click="swap(content.annexes,idx,content2 ? content2.annexes:null)" class="el-icon-bottom"/>
                      <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.annexes,idx-1,content2 ? content2.annexes:null)" class="el-icon-top"/>
                    </div>
                    <el-button size="mini" slot="reference">...</el-button>
                  </el-popover>
                </td>
              </tr>
              <tr v-if="showComment && remarksMap['content.annexes.' + annexe.k]" :key="annexe.k + 'c'">
                <td colspan="3" class="rowRemarkBorder">
                  <docItemRemarks :path="'content.annexes.' + annexe.k"/>
                </td>
              </tr>
            </template>
          </table>
          <div v-if="recommendAnnexes.length>0" class="recommendBack">
            <table class="table">
              <tr>
                <th>序号</th>
                <th>附件编号</th>
                <th>附件名称</th>
                <th>版本号</th>
              </tr>
              <tr v-for="(annex,idx) in recommendAnnexes">
                <td style="width:50px;border: 1px solid">
                  <a style="color: #0D8FBF" @click="previewRefFlow(annex.id)">{{ idx + 1 }}<a
                      class="el-icon-paperclip"/></a>
                </td>
                <td style="width: 20%;border: 1px solid">{{ annex.num }}</td>
                <td style="border: 1px solid">{{ annex.name }}</td>
                <td style="width: 20%;border: 1px solid">{{ annex.ver }}</td>
                <td class="buttonColumn">
                  <el-button v-if="editing" @click="content.annexes.push({num: annex.num, name:annex.name, name_E:annex.name_E, ver: annex.ver, refId:annex.id, k:genNewK()});recommendAnnexes.splice(idx,1)"
                             style="background:#409EFF;color: #fff;float: left;margin-right: 7px" type="primary" size="mini" class="el-icon-top-right"/>
                </td>
              </tr>
            </table>
          </div>
        </el-form-item>
      </div>
      <div>
        <h3 style="padding-bottom: 10px">第七部分：修订历史<i style="color: #0D8FBF;font-size: 20px;padding-left: 10px" class="el-icon-s-comment" @click="comment('content.histories')"/></h3>
        <docItemRemarks :path="'content.histories'"/>
      <el-form-item>
        <table class="table">
          <tr>
            <th>版本号</th>
            <th>修订说明</th>
          </tr>
          <template v-for="(history,idx) in content.histories">
          <tr :key="history.k">
            <td  style="width: 30%" :class="(content2 && !content2.histories[idx]) ?'modifyTag':'tdUnmark'">
              <currencyInput :editing="editing" :val.sync="history.ver" :path="'content.histories.' + history.k + '.ver'"
                             :modified="content2 && content2.histories[idx] && history.ver !=content2.histories[idx].ver" :w="285"/>
            </td>
            <td :class="(content2 && !content2.histories[idx]) ? 'modifyTag':'tdUnmark'">
              <docItem :valCn.sync="history.note" :valEn.sync="history.note_E" :path="'content.histories.' + history.k + '.note'" :w="670" :type="'text'"
                       :modifiedCn="content2 && content2.histories[idx] && history.note !=content2.histories[idx].note"
                       :modifiedEn="content2 && content2.histories[idx] && history.note_E !=content2.histories[idx].note_E"/>
            </td>
            <td class="buttonColumn">
              <el-button v-if="editing" @click="add(content.histories, idx,History(), content2 ? content2.histories:null)" class="el-icon-plus"/>
              <i @click="comment('content.histories.' + history.k)" class="el-icon-s-comment"/>
              <el-popover v-if="content.histories.length > 1 && editing" placement="right" width="180" trigger="click">
                <div style="float: left">
                  <el-button type="primary" size="mini" v-if="content.histories.length > 1" @click="strikeOut(content.histories,idx,content2 ? content2.histories:null)" class="el-icon-minus"/>
                  <el-button type="primary" size="mini" v-if="idx < content.histories.length-1" @click="swap(content.histories,idx,content2 ? content2.histories:null)" class="el-icon-bottom"/>
                  <el-button type="primary" size="mini" v-if="idx > 0" @click="swap(content.histories,idx-1,content2 ? content2.histories:null)" class="el-icon-top"/>
                </div>
                <el-button size="mini" slot="reference">...</el-button>
              </el-popover>
            </td>
          </tr>
            <tr v-if="showComment && remarksMap['content.histories.' + history.k]" :key="history.k + 'c'">
              <td colspan="2" class="rowRemarkBorder">
                <docItemRemarks :path="'content.histories.' + history.k"/>
              </td>
            </tr>
          </template>
        </table>
      </el-form-item>
      </div>
    </el-form>
    <selDeptDlg ref="selDeptDlg" :depts="depts" @oked="onSetRefDept"></selDeptDlg>
    <selRefDocDlg ref="selRefDocDlg" :prjt="{id:doc.prjtId,relation}" :myId="doc.id" :refFlows="content.refFlows" @oked="onSetRefDoc"/>
    <selRefDlg ref="selRefDlg" :docAnnexes="annexes" :annexes="content.annexes" :docTerms="terms" :terms="content.terms"
               :docAbbrs="abbrs" :abbrs="content.abbrs" :docLaws="laws" :laws="content.refLaws"  :refPropN="refPropN" @oked="onSetRef"/>
    <smsNotiDlg ref="smsNotiDlg" :pmStaffs="pmStaffs"  :authorStaffs="authorStaffs" :auditorStaffs="auditorStaffs" :docId="doc.id" :staffId="staffId" :amIAuthor="amIAuthor" :amIAuditor="amIAuditor"/>
    <el-dialog :title="'添加评论'" v-el-drag-dialog append-to-body :visible.sync="commenting" :modal="false">
      <el-input v-model="discuss" autocomplete="off" type="textarea" :autosize="{ minRows: 3}"></el-input>
      <dlgFoot slot="footer" @ok="onAddRemark" @cancel="commenting=false;"></dlgFoot>
    </el-dialog>
  </div>
</template>

<script>
import tabMix from "./tabMix";

export default {
  name: "editPolicy2",

  mixins:[tabMix],

}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>
