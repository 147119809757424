import formDlgMix from "@/gDlgs/formDlgMix";
import Vue from 'vue'

export default {
    name: "selRolesDlg",
    mixins: [formDlgMix],
    props: ['roles'],

    data() {
        return {
            checks: {},
            filterWord1: '',
            filterWord2: '',
        }
    },

    watch: {
        working(){
            Object.assign(this, {filterWord1: '', filterWord2: ''})
            for(let key in this.checks){
                delete this.checks[key];
            }
            this.$forceUpdate()
        }
    },

    computed: {
        displayRoles(){
            let {roles, filterWord2} = this
            if(filterWord2.length>0) roles = roles.filter(it=> it.name.toLowerCase().includes(filterWord2))
            return roles
        },

        displayCheckedRoles(){
            let {checks, roles} = this
            roles = roles.filter(role => checks[role.id])
            return roles
        },

        allSeled() {return this.displayRoles.every(it=>this.checks[it.id]);},

        isIndeterminate() { return  !(this.allSeled || this.displayRoles.every(it=>!this.checks[it.id]))},
    },

    methods: {
        batchSelection(){
            let {allSeled, isIndeterminate, displayRoles} = this
            let s = !(isIndeterminate || allSeled)
            displayRoles.forEach(it => this.$set(this.checks, it.id, s))
        },

        search() {
            this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
        },

        onOk(){
            let {checks} =this
            let checkedRoleIds = []
            for (let i in checks){
                if(checks[i]) checkedRoleIds.push(i)
            }
            this.$emit('oked',checkedRoleIds)
            this.working =false
        },
    }
};
